import { useParams, useLocation } from "react-router-dom";
import { useFetchArticleBySlug } from "../services/StrapiService";
import { Box, Container, Typography } from "@mui/material";
import { Article, ContentBlock, MetaSocial } from "../services/StrapiTypes";
import { BlockComponentMap } from "../components/blocks/BlockComponents";
import { CalculatorSkeleton } from "../components/skeletons/CalculatorSkeleton";
import NotFoundPage from "./NotFoundPage";
import { getImageProps } from "../services/StrapiHelpers";
import dayjs from "dayjs";
import { AuthorsList } from "../components/AuthorsList";
import { Helmet } from 'react-helmet-async';

function getArticleSEO(article: Article, pathType: string) {
  const defaultStructuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": article.title,
    "description": article.subheading,
    "datePublished": article.publishedAt,
    "dateModified": article.updatedAt,
    "dateCreated": article.createdAt,
    "author": article.authors.map((author: any) => ({
      "@type": "Person",
      "name": author.name
    }))
  };

  const seoBlock = article.seo_metadata;

  // Get image properties for either metaImage or coverImage
  const imageToUse = seoBlock?.metaImage || article.coverImage?.image;
  const imageProps = imageToUse ? getImageProps(imageToUse, 'large') : null;

  // Get the file extension to determine correct MIME type
  const fileExtension = imageProps?.src.split('.').pop()?.toLowerCase();
  const mimeType = fileExtension === 'png' ? 'image/png' :
    fileExtension === 'jpg' || fileExtension === 'jpeg' ? 'image/jpeg' :
      'image/png';

  // Create an array of all OG image meta tags
  const ogImageTags = imageProps ? [
    { property: "og:image", content: imageProps.src },
    { property: "og:image:secure_url", content: imageProps.src },
    { property: "og:image:type", content: mimeType },
    { property: "og:image:width", content: String(imageProps.width) },
    { property: "og:image:height", content: String(imageProps.height) }
  ] : [];

  // Safely get the type from the first category
  const type = article.categories?.[0]?.name || pathType;

  // Determine the path based on category type
  const path = type === 'beregner' ? 'calculators' :
    type === 'artikel' ? 'articles' :
      type === 'data' ? 'data' :
        pathType; // fallback to pathType if category is missing or unknown

  // Construct the full URL for the article
  const baseUrl = 'https://finansberegner.dk';
  const fullUrl = `${baseUrl}/${path}/${article.slug}`;

  return (
    <Helmet>
      <title>{seoBlock?.metaTitle || article.title}</title>
      <meta name="description" content={seoBlock?.metaDescription || article.subheading} />

      {/* OpenGraph tags - always include basic tags first */}
      <meta property="og:title" content={seoBlock?.metaTitle || article.title} />
      <meta property="og:description" content={seoBlock?.metaDescription || article.subheading} />
      <meta property="og:type" content="article" />

      {/* OpenGraph image tags */}
      {ogImageTags.map((tag, index) => (
        <meta
          key={`og-image-${index}`}
          property={tag.property}
          content={tag.content}
        />
      ))}

      {/* Facebook specific meta tags */}
      {seoBlock?.metaSocial?.map((social: MetaSocial) => {
        if (social.socialNetwork === 'Facebook') {
          return (
            <>
              <meta property="og:title" content={social.title} key={`${social.id}-title`} />
              <meta property="og:description" content={social.description} key={social.id} />
            </>
          );
        }
        return null;
      })}

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seoBlock?.metaTitle || article.title} />
      <meta name="twitter:description" content={seoBlock?.metaDescription || article.subheading} />

      {/* Additional SEO metadata */}
      {seoBlock?.keywords && <meta name="keywords" content={seoBlock.keywords} />}
      {seoBlock?.metaRobots && <meta name="robots" content={seoBlock.metaRobots} />}
      {seoBlock?.metaViewport && <meta name="viewport" content={seoBlock.metaViewport} />}

      {/* Schema.org structured data */}
      <script type="application/ld+json">
        {seoBlock?.structuredData || JSON.stringify(defaultStructuredData)}
      </script>

      {/* Updated canonical URL */}
      <link
        rel="canonical"
        href={seoBlock?.canonicalURL || fullUrl}
      />

      {/* Social Meta Tags */}
      {seoBlock?.metaSocial?.map((social: MetaSocial) => {
        if (social.socialNetwork === 'Facebook') {
          return (
            <>
              <meta property="og:title" content={social.title} key={`${social.id}-title`} />
              <meta property="og:description" content={social.description} key={social.id} />
            </>
          );
        }
        return (
          <meta
            key={social.id}
            name="twitter:description"
            content={social.description}
          />
        );
      })}

      {/* OpenGraph URL - always pointing to main domain */}
      <meta property="og:url" content={fullUrl} />
    </Helmet>
  );
}

export default function ArticleDetailPage() {
  const { slug } = useParams();
  const location = useLocation();
  const pathType = location.pathname.includes('/calculator') ? 'calculators' : 'articles';
  const articleQuery = useFetchArticleBySlug(slug || "");

  if (articleQuery.isLoading) {
    return (
      <CalculatorSkeleton maxWidth="1000px" inputFields={4} graphHeight={352} />
    );
  }

  if (articleQuery.error) return <div>Error loading calculator</div>;
  if (!articleQuery.data?.[0]) return <NotFoundPage />;

  const article = articleQuery.data[0];

  return (
    <>
      {getArticleSEO(article, pathType)}
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: 0,
        }}
      >
        <Box sx={{ width: '100%', maxWidth: '100%' }}>
          <Typography
            variant="h2"
            sx={{
              wordBreak: "break-word",
              hyphens: "auto",
              fontSize: {
                xs: "h3.fontSize",
                sm: "h2.fontSize"
              }
            }}
          >
            {article.title}
          </Typography>
          <Typography sx={{ marginBottom: 2 }} variant="body2" color="text.secondary">Udgivet {dayjs(article.createdAt).locale('da').format('D. MMMM YYYY')}</Typography>
          {article.coverImage?.image && (
            <img
              {...getImageProps(article.coverImage.image, "large", true)}
              style={{ width: "100%", height: "auto", marginBottom: 2 }}
            />
          )}
          <Typography
            variant="h5"
            sx={{ marginBottom: 2 }}
          >
            {article.subheading}
          </Typography>
          <Typography
            sx={{ marginBottom: 2, color: "text.secondary" }}
          >
            {article.description}
          </Typography>
          {article.blocks
            ?.sort((a, b) => a.displayOrder - b.displayOrder)
            .map((block: ContentBlock, index: number) =>
              BlockComponentMap[block.__component]?.(block as any, index)
            )}
          {article.showAuthors && article.authors.length > 0 && (
            <>
              <Typography sx={{ marginTop: 4 }} variant="body1" color="text.primary">Skrevet af</Typography>
              <AuthorsList authors={article.authors} showAuthors={article.showAuthors} />
            </>
          )}
        </Box>
      </Container>
    </>
  );
}
