import { CalculatorLinksSkeleton } from "../components/skeletons/CalculatorLinksSkeleton";
import { useFetchDataPageData } from "../services/StrapiService";
import { CalculatorLinks } from "./homepage/CalculatorLinks";
import { RichTextNode } from "../services/StrapiTypes";
import { RichTextParagraph } from "../components/blocks/BlockComponents";


export default function DataPage() {
  const dataPageQuery = useFetchDataPageData();

  return (
    <>
      {dataPageQuery.isLoading ? (
        <CalculatorLinksSkeleton skeletonCount={5} />
      ) : (
        <>
          {dataPageQuery.data?.introtext
            ?.map((block: RichTextNode, index: number) =>
              <RichTextParagraph key={index} node={block} />
            )}
          <CalculatorLinks
            calculatorLinks={dataPageQuery.data?.calculatorLinks ?? []}
          />
        </>
      )}
    </>
  );
}
