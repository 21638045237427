import { useQuery } from '@tanstack/react-query';

export interface EODHDDataPoint {
    date: string;
    open: number;
    high: number;
    low: number;
    close: number;
    adjusted_close: number;
    volume: number;
}


export interface IndexOption {
    label: string;
    ticker: string;
}


const baseUrl = process.env.REACT_APP_API_ENDPOINT_BACKEND;

interface HistoricalDataParams {
    ticker: string;
    exchange: string;
    startDate?: string;
    endDate?: string;
}

export function useHistoricalData({
    ticker,
    exchange,
    startDate,
    endDate
}: HistoricalDataParams) {
    return useQuery({
        queryKey: ['historicalData', ticker, exchange, startDate, endDate],
        queryFn: async () => {
            try {
                let url = `${baseUrl}/api/stock-data?ticker=${ticker}&exchange=${exchange}`;
                if (startDate) url += `&start_date=${startDate}`;
                if (endDate) url += `&end_date=${endDate}`;

                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                return response.json() as Promise<EODHDDataPoint[]>;
            } catch (error) {
                console.error('Error fetching stock data:', error);
                throw error;
            }
        },
        enabled: !!ticker && !!exchange, // Only run query if ticker and exchange exist
    });
}
