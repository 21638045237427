import { BaseResponseData } from "./StrapiTypes";

export interface ApiResponse<T extends object = {}> {
  data: BaseResponseData & T;
}

export class ApiService {
  public static async makeRequest<T extends object = {}>(
    endpoint: string,
    options: RequestInit = {}
  ): Promise<T> {
    const endpoint_base = process.env.REACT_APP_API_ENDPOINT_BASE || 'https://strapi.finansberegner.dk/api';

    const response = await fetch(endpoint_base + endpoint, {
      ...options,
      headers: {
        "Content-Type": "application/json",
        ...options.headers,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Something went wrong");
    }

    return response.json() as Promise<T>;
  }
}

export class ApiServiceBackend {
  public static async makeRequest<T extends object = {}>(
    endpoint: string,
    options: RequestInit = {}
  ): Promise<T> {
    const endpoint_base =
      process.env.REACT_APP_API_ENDPOINT_BACKEND || "https://api.finansberegner.dk";
    const url = endpoint_base + endpoint;

    const response = await fetch(url, {
      ...options,
      credentials: 'same-origin',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...options.headers,
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Something went wrong");
    }

    return response.json() as Promise<T>;
  }
}