import { Card, useTheme } from "@mui/material";
import { LineChart } from "@mui/x-charts/LineChart";
import { useMemo } from "react";
import { formatCurrency } from "../../utils/formatters";
import { axisClasses } from "@mui/x-charts";

interface FutureInflationGraphProps {
  startValue: number;
  inflation: number;
  years: number;
}

export function FutureInflationGraph(props: FutureInflationGraphProps) {
  const { startValue, inflation, years } = props;

  const graphData = useMemo(() => {
    const yearArray: number[] = [];
    const valueArray: number[] = [];

    for (let year = 0; year <= years; year++) {
      yearArray.push(year);
      valueArray.push(startValue / Math.pow(1 + inflation / 100, year));
    }

    return { years: yearArray, values: valueArray };
  }, [startValue, inflation, years]);

  const theme = useTheme();

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.getContrastText(
          theme.palette.primary.main
        ),
        marginTop: 1,
        borderRadius: 1,
        boxShadow: 3,
      }}
    >
      <LineChart
        series={[
          {
            label: "Købekraft over tid",
            data: graphData.values,
            color: theme.palette.primary.main,
            valueFormatter: (value) => {
              return value === null ? "" : formatCurrency(value, 0);
            },
          },
        ]}
        height={232}
        slotProps={{ legend: { hidden: true } }}
        xAxis={[
          {
            data: graphData.years,
            scaleType: "linear",
            valueFormatter: (value) => `År ${value}`,
            label: "År",
          },
        ]}
        yAxis={[
          {
            label: "Købekraft (DKK)",
            valueFormatter: (value) => formatCurrency(value, 0),
          },
        ]}
        sx={{
          margin: 0.5,
          [`& .${axisClasses.left} .${axisClasses.label}`]: {
            transform: "translateX(-30px)",
          },
        }}
        margin={{ top: 20, bottom: 50, left: 100, right: 10 }}
      />
    </Card>
  );
} 