import { useEffect, useRef, useState } from 'react';
import { useLogFormInput } from '../services/BackendService';
import { v4 as uuidv4 } from 'uuid';

interface UseFormLoggerOptions {
    formName: string;
    values: Record<string, any>;
    debounceMs?: number;
}

export function useFormLogger({ formName, values, debounceMs = 300 }: UseFormLoggerOptions) {
    const [sessionId] = useState(() => uuidv4());
    const logFormInput = useLogFormInput();
    const previousValues = useRef(values);

    useEffect(() => {
        const handler = setTimeout(() => {
            const hasChanged = Object.entries(values).some(
                ([key, value]) => previousValues.current[key] !== value
            );

            if (hasChanged) {
                previousValues.current = values;

                logFormInput.mutate({
                    form_name: formName,
                    input_values: values,
                    session_id: sessionId
                });
            }
        }, debounceMs);

        return () => clearTimeout(handler);
    }, [values, sessionId, formName, debounceMs, logFormInput]);
}
