import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { FBSlider } from "../FBSlider";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined"
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import { calculateCompoundGrowth } from "../../utils/calculations";
import { formatCurrency } from "../../utils/formatters";
import { SummaryBox } from "./SummaryBox";
import BoyOutlinedIcon from '@mui/icons-material/BoyOutlined';
import { BoerneOpsparingGraph } from "./BoerneOpsparingDKGraph";
import { useFormLogger } from "../../hooks/useFormLogger";
import { useSearchParams } from "react-router-dom";

export default function BoerneOpsparingDKCalculator() {
  const [searchParams] = useSearchParams();

  const INITIAL_VALUES = {
    startValue: 0,
    deposit: 3000,
    interest: 7.0,
    startAge: 0,
  };

  // Update state definitions to use search params
  const [startValue, setStartValue] = useState<number>(Number(searchParams.get('startValue')) || INITIAL_VALUES.startValue);
  const [deposit, setDeposit] = useState<number>(Number(searchParams.get('deposit')) || INITIAL_VALUES.deposit);
  const [interest, setInterest] = useState<number>(Number(searchParams.get('interest')) || INITIAL_VALUES.interest);
  const [startAge, setStartAge] = useState<number>(Number(searchParams.get('startAge')) || INITIAL_VALUES.startAge);

  // Add form logging - only tracking user inputs
  useFormLogger({
    formName: 'boerne_opsparing_calculator',
    values: {
      startValue,
      deposit,
      interest,
      startAge
    }
  });

  const [calculationValues, setCalculationValues] = useState({
    startValue: 0,
    deposit: 3000,
    interest: 7.0,
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      setCalculationValues({
        startValue,
        deposit: deposit / 12,
        interest,
      });
    }, 300);

    return () => clearTimeout(handler);
  }, [startValue, deposit, interest]);

  // First, define the payout ages
  const PAYOUT_AGES = [14, 18, 21];

  // Calculate results for each payout age
  const resultsByAge = PAYOUT_AGES.map(payoutAge => {
    const years = Math.max(0, payoutAge - startAge);
    return {
      payoutAge,
      years,
      ...calculateCompoundGrowth(
        calculationValues.startValue,
        calculationValues.deposit,
        calculationValues.interest,
        years,
        72000
      )[years - 1] // Get the last result for this period
    };
  });

  const age21Results = resultsByAge.find(result => result.payoutAge === 21);

  // Add stateParams calculation before the SummaryBox
  const stateParams = Object.entries({
    startValue,
    deposit,
    interest,
    startAge,
  }).reduce((acc, [key, value]) => {
    if (value !== INITIAL_VALUES[key as keyof typeof INITIAL_VALUES]) {
      acc[key] = value as number;
    }
    return acc;
  }, {} as Record<string, number>);

  return (
    <Box
      sx={{
        display: "flex",
        my: 2,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 1,
          }}
        >
          <Box
            sx={{
              flex: { xs: "1", md: "0 0 400px" },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FBSlider
              title={"Start"}
              valuePostFix=" kr."
              min={0}
              max={72000}
              steps={100}
              value={startValue}
              setValue={setStartValue}
              icon={SavingsOutlinedIcon}
            />
            <FBSlider
              title={"Årligt indskud"}
              valuePostFix=" kr."
              min={0}
              max={6000}
              steps={50}
              editable={false}
              value={deposit}
              setValue={setDeposit}
              icon={CalendarMonthOutlinedIcon}
            />
            <FBSlider
              title={"Afkast"}
              valuePostFix="%"
              min={0}
              max={10}
              steps={0.1}
              value={interest}
              setValue={setInterest}
              formatter={(value: number) => value.toFixed(1)}
              icon={TrendingUpOutlinedIcon}
            />
            <FBSlider
              title={"Start Alder"}
              valuePostFix=" år"
              min={0}
              max={13}
              steps={1}
              value={startAge}
              setValue={setStartAge}
              icon={BoyOutlinedIcon}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Summary Box */}
              <SummaryBox
                title={`Værdi ved ${21} år`}
                mainValue={`${formatCurrency(age21Results?.currentValue || 0)} kr.`}
                secondaryValues={[
                  {
                    label: "Indskudt",
                    value: `${formatCurrency(age21Results?.totalDeposits || 0)} kr.`,
                    percentage: isNaN((age21Results?.totalDeposits || 0) / (age21Results?.currentValue || 1))
                      ? 0
                      : ((age21Results?.totalDeposits || 0) / (age21Results?.currentValue || 1)) * 100,
                  },
                  {
                    label: "Afkast",
                    value: `${formatCurrency((age21Results?.currentValue || 0) - (age21Results?.totalDeposits || 0))} kr.`,
                    percentage: isNaN(((age21Results?.currentValue || 0) - (age21Results?.totalDeposits || 0)) / (age21Results?.currentValue || 1))
                      ? 0
                      : (((age21Results?.currentValue || 0) - (age21Results?.totalDeposits || 0)) / (age21Results?.currentValue || 1)) * 100,
                  },
                ]}
                share={true}
                stateParams={stateParams}
              />

              {/* Graph */}
              <Box>
                <BoerneOpsparingGraph data={resultsByAge} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
