import { Box, Modal, Typography, TextField, Button, Snackbar, Tooltip, useTheme } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RedditIcon from '@mui/icons-material/Reddit';
import { useState } from "react";
import { InputAdornment } from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

interface ShareModalProps {
  open: boolean;
  onClose: () => void;
  stateParams?: Record<string, string | number | null>;
  warning?: string;
}

export function ShareModal({ open, onClose, stateParams = {}, warning }: ShareModalProps) {
  const [copied, setCopied] = useState(false);


  const constructShareableUrl = () => {
    const baseUrl = 'https://finansberegner.dk' + window.location.pathname;
    const searchParams = new URLSearchParams();

    Object.entries(stateParams).forEach(([key, value]) => {
      if (value !== null) {
        searchParams.append(key, value.toString());
      }
    });

    const queryString = searchParams.toString();
    return queryString ? `${baseUrl}?${queryString}` : baseUrl;
  };

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(constructShareableUrl());
      setCopied(true);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleSocialShare = (platform: string) => {
    const url = constructShareableUrl();
    const summary = encodeURIComponent("Udregnet på finansberegner.dk");

    const urls = {
      facebook: `https://www.facebook.com/share.php?u=${encodeURIComponent(url)}`,
      twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${summary}`,
      linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&summary=${summary}`,
      reddit: `https://www.reddit.com/submit?url=${encodeURIComponent(url)}&title=${summary}`,
    };

    const shareUrl = urls[platform as keyof typeof urls];

    window.open(shareUrl, '_blank', 'noopener,noreferrer');
  };

  const theme = useTheme();

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="share-modal"
      sx={{
        backdropFilter: 'blur(2px)',
      }}
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: "90%", md: 600 },
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 1,
      }}>
        <Typography variant="h6" component="h2">
          Del dine tal!
        </Typography>
        {warning && <Typography variant="body1" color="error" sx={{ mt: 1 }} fontSize={"0.85rem"}>{warning}</Typography>}
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            disabled
            value={constructShareableUrl()}
            size="small"
            slotProps={{
              input: {
                endAdornment: <InputAdornment position="end">
                  {
                    <Tooltip
                      title={copied ? "Kopieret til udklipsholder" : "Kopier til udklipsholder"}
                    >
                      <Button
                        onClick={handleCopyToClipboard}
                        sx={{
                          pointerEvents: "auto", // Ensure the button remains clickable, even though the field is disabled
                          backgroundColor: "transparent",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                          color: theme.palette.primary.main,
                          mr: -2
                        }}
                      >
                        {copied ? <CheckOutlinedIcon /> : <ContentCopyIcon />}
                      </Button>
                    </Tooltip>
                  }
                </InputAdornment>,
              },
            }}
          />
        </Box>

        <Box sx={{
          mt: 3,
          display: 'grid',
          gridTemplateColumns: { xs: '1fr 1fr', md: 'repeat(4, 1fr)' },
          gap: 2,
          width: '100%'
        }}>
          <Button
            variant="contained"
            onClick={() => handleSocialShare('facebook')}
            startIcon={<FacebookIcon />}
            sx={{ bgcolor: '#1877F2', textTransform: 'none' }}
            fullWidth
          >
            Facebook
          </Button>
          <Button
            variant="contained"
            onClick={() => handleSocialShare('twitter')}
            startIcon={<TwitterIcon />}
            sx={{ bgcolor: '#000000', textTransform: 'none' }}
            fullWidth
          >
            X
          </Button>
          <Button
            variant="contained"
            onClick={() => handleSocialShare('linkedin')}
            startIcon={<LinkedInIcon />}
            sx={{ bgcolor: '#0A66C2', textTransform: 'none' }}
            fullWidth
          >
            LinkedIn
          </Button>
          <Button
            variant="contained"
            onClick={() => handleSocialShare('reddit')}
            startIcon={<RedditIcon />}
            sx={{ bgcolor: '#FF4500', textTransform: 'none' }}
            fullWidth
          >
            Reddit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
} 