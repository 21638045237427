import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

interface FBLoadingProps {
    text?: string;
}

export default function FBLoading(props: FBLoadingProps) {
    const { text = 'henter data...' } = props;

    return (
        <Box
            display="flex"
            gap={2}
            alignItems="center"
        >
            <CircularProgress color="primary" />
            <Typography variant="body1" color="text.secondary">
                {text}
            </Typography>
        </Box>
    );
};