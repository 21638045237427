import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Box, useTheme } from '@mui/material';
import FBLoading from '../FBLoading';

interface IndexDisplayGraphProps {
  data: any[];
  isLoading: boolean;
}

export default function IndexDisplayGraph({
  data,
  isLoading
}: IndexDisplayGraphProps) {
  const theme = useTheme();

  const chartData = data.map(item => {
    const date = new Date(item.date);
    return {
      date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
      close: item.close
    };
  });

  if (!data) return <Box>No data available</Box>;

  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          height: 400,
          position: 'relative',
          paddingTop: 2
        }}
      >
        {data.length > 0 && (
          <Box style={{ opacity: isLoading ? 0.1 : 1, width: '100%', height: '100%' }}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={chartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  angle={-45}
                  textAnchor="end"
                  height={70}
                  tickFormatter={(label) => {
                    const [day, month, year] = label.split('/');
                    const date = new Date(Number(year), Number(month) - 1, Number(day));
                    return `${date.getDate()}/${date.getMonth() + 1}-${date.getFullYear()}`;
                  }}
                />
                <YAxis
                  domain={['auto', 'auto']}
                />
                <Tooltip
                  labelFormatter={(label) => {
                    const [day, month, year] = label.split('/');
                    const date = new Date(Number(year), Number(month) - 1, Number(day));
                    return `${date.getDate()}/${date.getMonth() + 1}-${date.getFullYear()}`;
                  }}
                  formatter={(value: number) => [`${value.toFixed(2)}`, 'Kurs']}
                />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="close"
                  strokeWidth={2}
                  stroke={theme.palette.primary.main}
                  dot={false}
                  name="Kurs"
                />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        )}
        {isLoading && (
          <Box
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
            }}
          >
            <FBLoading />
          </Box>
        )}
      </Box>
    </Box>
  );
}