import { InflationData } from "../services/BackendTypes";

// Add interface for the return type
interface GrowthResult {
  year: number;
  currentValue: number;
  totalDeposits: number;
}

export function calculateCompoundGrowth(
  startValue: number,
  monthlyDeposit: number,
  interest: number,
  totalYears: number,
  depositLimit?: number
): GrowthResult[] {
  const years = totalYears;
  const yearlyDeposit = monthlyDeposit * 12;
  let totalDeposits = startValue;
  let currentValue = startValue;

  const results = [];

  for (let year = 1; year <= years; year++) {
    if (depositLimit && totalDeposits + yearlyDeposit > depositLimit) {
      if (totalDeposits < depositLimit) {
        const remainingDeposit = depositLimit - totalDeposits;
        totalDeposits += remainingDeposit;
        currentValue += remainingDeposit;
      }
    } else {
      totalDeposits += yearlyDeposit;
      currentValue += yearlyDeposit;
    }

    currentValue *= 1 + interest / 100;
    results.push({
      year,
      currentValue: parseFloat(currentValue.toFixed(0)),
      totalDeposits: parseFloat(totalDeposits.toFixed(0)),
    });
  }

  return results;
}


interface TimeToTargetResult {
  years: number;
  totalDeposits: number;
  totalInterest: number;
  finalAmount: number;
}

export function calculateYearsToTarget(
  startValue: number,
  monthlyDeposit: number,
  interest: number,
  target: number
): TimeToTargetResult {
  if (interest === 0 && monthlyDeposit === 0) {
    return {
      years: Infinity,
      totalDeposits: startValue,
      totalInterest: 0,
      finalAmount: startValue
    };
  }

  if (startValue >= target) {
    return {
      years: 0,
      totalDeposits: startValue,
      totalInterest: 0,
      finalAmount: startValue
    };
  }

  const monthlyInterest = interest / 12 / 100;
  let totalDeposits = startValue;
  let currentValue = startValue;
  let months = 0;

  do {
    months++;
    totalDeposits += monthlyDeposit;
    currentValue += monthlyDeposit;
    currentValue *= 1 + monthlyInterest;

    if (months > 1000 * 12) {
      return {
        years: Infinity,
        totalDeposits: startValue,
        totalInterest: 0,
        finalAmount: currentValue
      };
    }
  } while (currentValue <= target);

  const years = months / 12;
  const totalInterest = currentValue - totalDeposits;

  return {
    years,
    totalDeposits,
    totalInterest,
    finalAmount: currentValue
  };
}

interface InflationResult {
  adjustedAmount: number;
  percentageChange: number;
}

export const calculateInflation = (
  startYear: number,
  endYear: number,
  amount: number,
  inflationData: InflationData[]
): InflationResult => {
  const startPrice = inflationData.find(data => data.year === startYear)?.index_value;
  const endPrice = inflationData.find(data => data.year === endYear)?.index_value;

  if (!startPrice || !endPrice) {
    throw new Error("Inflation data not found for the specified years");
  }

  // Calculate the inflation multiplier
  const multiplier = endPrice / startPrice;
  const adjustedAmount = amount * multiplier;
  const percentageChange = (adjustedAmount / amount - 1) * 100;

  return {
    adjustedAmount: Math.round(adjustedAmount * 100) / 100,
    percentageChange: Math.round(percentageChange * 10) / 10,
  };
};

interface ArpResult {
  yearlyValues: number[];
  yearlyFees: number[];
}

export function calculateArpAdjustedReturn(
  totalYears: number,
  arp: number,
  startValue: number,
  annualReturn: number,
  monthlyDeposit: number = 0
): ArpResult {
  const yearlyValues: number[] = new Array(totalYears + 1);
  const yearlyFees: number[] = new Array(totalYears + 1);

  // Initialize starting values
  yearlyValues[0] = startValue;
  yearlyFees[0] = 0;

  const yearlyDeposit = monthlyDeposit * 12;

  // Calculate yearly values and fees
  for (let year = 1; year <= totalYears; year++) {
    // Add yearly deposit
    yearlyValues[year] = yearlyValues[year - 1] + yearlyDeposit;

    // Calculate growth before ARP
    yearlyValues[year] = yearlyValues[year] * (1 + annualReturn / 100);

    // Calculate ARP fee for this year
    yearlyFees[year] = (yearlyValues[year] * (arp / 100));

    // Subtract ARP fee from value
    yearlyValues[year] = yearlyValues[year] - yearlyFees[year];
  }

  return {
    yearlyValues: yearlyValues.map(value => parseFloat(value.toFixed(0))),
    yearlyFees: yearlyFees.map(fee => parseFloat(fee.toFixed(0)))
  };
}

