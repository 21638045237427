import { useEffect, useState } from "react";
import { FBSlider } from "../FBSlider";
import { useFetchInflationData, useFetchLatestUpdatedAt } from "../../services/BackendService";
import { Box, Typography, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { calculateInflation } from "../../utils/calculations";
import { SummaryBox } from "./SummaryBox";
import { formatCurrency } from "../../utils/formatters";
import { InflationGraph } from "./InflationGraph";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import { CalculatorSkeleton } from "../skeletons/CalculatorSkeleton";
import { useFormLogger } from "../../hooks/useFormLogger";
import { useSearchParams } from "react-router-dom";
import { useMediaQuery, Theme } from "@mui/material";
import FBLoading from "../FBLoading";

export function InflationCalculator() {
  const [searchParams] = useSearchParams();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const INITIAL_VALUES = {
    startYear: 2000,
    value: 10000,
    country: "DK" as "DK" | "US" | "SE",
  };

  type CountryCode = "DK" | "US" | "SE";

  const COUNTRY_NAMES: Record<CountryCode, string> = {
    DK: "Danmark",
    US: "USA",
    SE: "Sverige"
  };

  const [country, setCountry] = useState<CountryCode>(
    (searchParams.get('country') as CountryCode) || INITIAL_VALUES.country
  );

  // Update state definitions to use search params
  const [startYear, setStartYear] = useState<number>(Number(searchParams.get('startYear')) || INITIAL_VALUES.startYear);
  const [endYear, setEndYear] = useState<number>(0);
  const [value, setValue] = useState<number>(Number(searchParams.get('value')) || INITIAL_VALUES.value);

  const { data: inflationData, isLoading } = useFetchInflationData(country);
  const { data: latestUpdatedAt } = useFetchLatestUpdatedAt(`inflation_${country.toLowerCase()}`);

  // Add country change handler
  const handleCountryChange = (
    event: React.MouseEvent<HTMLElement>,
    newCountry: "DK" | "US" | "SE" | null,
  ) => {
    if (newCountry !== null) {
      setCountry(newCountry);
    }
  };

  // Add form logging - only tracking user inputs
  useFormLogger({
    formName: 'inflation_calculator',
    values: {
      startYear,
      endYear,
      value,
      country
    }
  });

  // Add state for calculation results
  const [calculationValues, setCalculationValues] = useState({
    value: 0,
    startYear: 0,
    endYear: 0,
  });

  const [adjustedAmount, setAdjustedAmount] = useState<number>(0);
  const [percentageChange, setPercentageChange] = useState<number>(0);
  const [averageInflation, setAverageInflation] = useState<number>(0);

  useEffect(() => {
    if (inflationData && inflationData.length > 0) {
      if (!searchParams.get('startYear')) {
        setStartYear(INITIAL_VALUES.startYear);
      }
      if (!searchParams.get('endYear')) {
        setEndYear(inflationData[0].year);
      }
    }
  }, [inflationData, searchParams]);

  useEffect(() => {
    if (!inflationData) return;

    const handler = setTimeout(() => {
      setCalculationValues({
        value,
        startYear,
        endYear,
      });

      if (value > 0) {
        const result = calculateInflation(
          startYear,
          endYear,
          value,
          inflationData
        );

        // Calculate average inflation for the selected period
        const relevantData = inflationData
          .filter(d => {
            // Include data between start and end years, regardless of order
            const minYear = Math.min(startYear, endYear);
            const maxYear = Math.max(startYear, endYear);
            return d.year >= minYear && d.year <= maxYear;
          })
          .sort((a, b) =>
            // Sort based on direction (forward or backward in time)
            startYear <= endYear ? a.year - b.year : b.year - a.year
          );

        let inflationRates: number[] = [];

        // Calculate year-over-year inflation rates
        for (let i = 0; i < relevantData.length - 1; i++) {
          const current = relevantData[i];
          const next = relevantData[i + 1];

          const percentageChange = current.index_value > 0
            ? ((next.index_value - current.index_value) / current.index_value) * 100
            : 0;

          // Invert the percentage if going backward in time
          const adjustedChange = startYear > endYear ? -percentageChange : percentageChange;
          inflationRates.push(isNaN(adjustedChange) ? 0 : adjustedChange);
        }

        const averageInflation = inflationRates.length > 0
          ? inflationRates.reduce((acc, curr) => acc + curr, 0) / inflationRates.length
          : 0;

        setAdjustedAmount(result.adjustedAmount);
        setPercentageChange(result.percentageChange);
        setAverageInflation(averageInflation);
      }
    }, 300);

    return () => clearTimeout(handler);
  }, [value, startYear, endYear, inflationData]);

  // Simple loading state handling
  if (!inflationData) {
    return <CalculatorSkeleton maxWidth="100%" inputFields={3} graphHeight={232} />;
  }

  // Update stateParams
  const stateParams = Object.entries({
    startYear,
    endYear,
    value,
    country,
  }).reduce((acc, [key, value]) => {
    if (value !== INITIAL_VALUES[key as keyof typeof INITIAL_VALUES]) {
      acc[key] = value;
    }
    return acc;
  }, {} as Record<string, number | string>);

  // Add helper function for currency symbol
  const getCurrencySymbol = (countryCode: string) => {
    switch (countryCode) {
      case 'US':
        return '$';
      case 'SE':
        return 'kr';
      case 'DK':
      default:
        return 'kr';
    }
  };

  // Add helper function for source attribution
  const getSourceAttribution = (countryCode: string) => {
    switch (countryCode) {
      case 'US':
        return {
          agency: 'U.S. Department of Labor Bureau of Labor Statistic',
          url: 'https://www.usinflationcalculator.com/inflation/consumer-price-index-and-annual-percent-changes-from-1913-to-2008/'
        };
      case 'SE':
        return {
          agency: 'Statistikmyndigheten SCB',
          url: 'https://www.scb.se/hitta-statistik/statistik-efter-amne/priser-och-konsumtion/konsumentprisindex/konsumentprisindex-kpi/pong/tabell-och-diagram/konsumentprisindex-kpi/kpi-historiska-tal-1830/'
        };
      default:
        return {
          agency: 'Danmarks Statistik',
          url: 'https://www.dst.dk/'
        };
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
      }}
    >
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <FBLoading text="Henter nye data..." />
        </Box>
      )}
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Add country selector */}
            <ToggleButtonGroup
              value={country}
              exclusive
              onChange={handleCountryChange}
              sx={{
                marginTop: 1,
                "& .MuiToggleButton-root": {
                  flex: 1,
                  fontSize: "1.2rem",
                },
                "& .MuiToggleButton-root:not(.Mui-selected)": {
                  color: theme => theme.palette.secondary.main
                },
                "& .MuiToggleButton-root.Mui-selected": {
                  backgroundColor: theme => theme.palette.primary.main,
                  color: theme => theme.palette.primary.contrastText,
                  "&:hover": {
                    backgroundColor: theme => theme.palette.primary.dark,
                  }
                },
              }}
            >
              <ToggleButton value="DK" sx={{ boxShadow: 3 }}>🇩🇰 DK</ToggleButton>
              <ToggleButton value="US" sx={{ boxShadow: 3 }}>🇺🇸 US</ToggleButton>
              <ToggleButton value="SE" sx={{ boxShadow: 3 }}>🇸🇪 SE</ToggleButton>
            </ToggleButtonGroup>

            <FBSlider
              title={"Start år"}
              min={inflationData[inflationData.length - 1].year}
              max={inflationData[0].year}
              editable={false}
              value={startYear}
              setValue={setStartYear}
              steps={1}
              formatter={(value) => value.toString()}
              icon={TodayOutlinedIcon}
            />
            <FBSlider
              title={"Slut år"}
              valuePostFix=""
              min={inflationData[inflationData.length - 1].year}
              max={inflationData[0].year}
              value={endYear}
              setValue={setEndYear}
              editable={false}
              steps={1}
              formatter={(value) => value.toString()}
              icon={EventOutlinedIcon}
            />
            <FBSlider
              title={"Beløb (tryk på tallet for at indtaste)"}
              valuePostFix={` ${getCurrencySymbol(country)}`}
              min={0}
              max={100000}
              steps={100000 / 100}
              value={value}
              setValue={setValue}
              icon={PaidOutlinedIcon}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Summary Box */}
              <SummaryBox
                title={`${formatCurrency(calculationValues.value)} ${getCurrencySymbol(country)} i ${calculationValues.startYear} svarer til`}
                mainValue={`${formatCurrency(adjustedAmount)} ${getCurrencySymbol(country)}`}
                mainSubtitle={`i ${calculationValues.endYear}`}
                secondaryValues={[
                  {
                    label: isMobile
                      ? "En % ændring på"
                      : "En procentvis ændring på",
                    value: `${formatCurrency(percentageChange, 1, 1)}%`,
                  },
                  {
                    label: "En gennemsnitlig inflation",
                    value: `${formatCurrency(averageInflation, 1, 1)}%`,
                  },
                ]}
                share={true}
                stateParams={stateParams}
                warning={(endYear === inflationData[0].year || startYear === inflationData[0].year) ?
                  '*Bemærk at dette link indeholder det seneste år, og værdien kan ændre sig når nye inflationstal offentliggøres.' :
                  undefined
                }
              />

              {/* Graph */}
              <Box>
                <InflationGraph
                  inflationData={inflationData}
                  startYear={calculationValues.startYear}
                  endYear={calculationValues.endYear}
                  initialValue={calculationValues.value}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        {latestUpdatedAt && (
          <Box>
            <Typography variant="subtitle2" fontWeight={200} fontSize={12} paddingTop={1.5}>
              (Tal for inflation i {COUNTRY_NAMES[country]} er sidst opdateret i {
                new Intl.DateTimeFormat('da-DK', {
                  month: 'long',
                  year: 'numeric'
                }).format(new Date(latestUpdatedAt.last_updated_at))
              } fra <u><a href={getSourceAttribution(country).url} target="_blank" rel="noopener noreferrer">{getSourceAttribution(country).agency}</a></u>)
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
