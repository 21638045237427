import { Box, useTheme } from "@mui/material";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import FBDatePicker from "../FBDatePicker";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined"
import { IndexOption, useHistoricalData } from "../../services/EODHDService";
import { SummaryBox } from "../calculators/SummaryBox";
import IndexDisplayGraph from "./IndexDisplayGraph";
import { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

const indices: (IndexOption & { exchange?: string, maxDate?: string })[] = [
    { label: 'OMXC25', ticker: 'OMXC25', exchange: 'INDX', maxDate: '2010-12-20' },
    { label: 'S&P 500', ticker: 'GSPC', exchange: 'INDX', maxDate: '1927-12-30' },
    { label: 'NASDAQ 100', ticker: 'NDX', exchange: 'INDX', maxDate: '1985-09-25' },
    { label: 'MSCI World', ticker: 'URTH', exchange: 'US', maxDate: '2012-01-12' }
];

export default function IndexDisplay() {
    const [searchParams] = useSearchParams();
    const today = new Date();
    const oneYearAgo = new Date(today);
    oneYearAgo.setFullYear(today.getFullYear() - 1);

    const [startDate, setStartDate] = useState<Date | null>(() => {
        const startDateParam = searchParams.get('startDate');
        return startDateParam ? new Date(startDateParam) : oneYearAgo;
    });
    const [endDate, setEndDate] = useState<Date | null>(() => {
        const endDateParam = searchParams.get('endDate');
        return endDateParam ? new Date(endDateParam) : today;
    });
    const [graphStartDate, setGraphStartDate] = useState<string>(() => {
        const startDateParam = searchParams.get('startDate');
        return startDateParam ? new Date(startDateParam).toISOString().split('T')[0] : oneYearAgo.toISOString().split('T')[0];
    });
    const [graphEndDate, setGraphEndDate] = useState<string>(() => {
        const endDateParam = searchParams.get('endDate');
        return endDateParam ? new Date(endDateParam).toISOString().split('T')[0] : today.toISOString().split('T')[0];
    });
    const [selectedIndex, setSelectedIndex] = useState<string>(
        searchParams.get('selectedIndex') || indices[0].ticker
    );
    const [indexData, setIndexData] = useState<any[]>([]);
    const [displayMode, setDisplayMode] = useState<'value' | 'index'>(
        (searchParams.get('displayMode') as 'value' | 'index') || 'value'
    );
    const [selectedRange, setSelectedRange] = useState<string>(
        searchParams.get('selectedRange') || '1Y'
    );

    const theme = useTheme();

    const {
        data: historicalData,
        isLoading,
        error
    } = useHistoricalData({
        ticker: selectedIndex,
        exchange: indices.find(i => i.ticker === selectedIndex)?.exchange || 'INDX',
        startDate: graphStartDate,
        endDate: graphEndDate
    });

    // Replace the useCallback with useEffect
    useEffect(() => {
        if (historicalData) {
            if (displayMode === 'index' && historicalData.length > 0) {
                const baseValue = historicalData[0].close;
                const indexedData = historicalData.map(item => ({
                    ...item,
                    close: (item.close / baseValue) * 100
                }));
                setIndexData(indexedData);
            } else {
                setIndexData(historicalData);
            }
        }
    }, [historicalData, displayMode]);

    const handleDateChange = useCallback((date: Date | null, isStart: boolean) => {
        setTimeout(() => {
            if (date && !isNaN(date.getTime())) {
                try {
                    // Check if the year is complete (4 digits)
                    const yearString = date.getFullYear().toString();
                    if (yearString.length !== 4) {
                        return;
                    }

                    const formattedDate = date.toISOString().split('T')[0];
                    if (isStart) {
                        setGraphStartDate(formattedDate);
                    } else {
                        setGraphEndDate(formattedDate);
                    }
                } catch (error) {
                    console.error('Invalid date:', error);
                }
            }
        }, 300);
    }, []);

    const handleIndexChange = (
        event: React.MouseEvent<HTMLElement>,
        newIndex: string,
    ) => {
        if (newIndex !== null) {
            setSelectedIndex(newIndex);
        }
    };

    const handleDisplayModeChange = (
        event: React.MouseEvent<HTMLElement>,
        newMode: 'value' | 'index' | null,
    ) => {
        if (newMode !== null) {
            setDisplayMode(newMode);
        }
    };

    const totalPercentageChange = indexData.length > 0
        ? ((indexData[indexData.length - 1].close / indexData[0].close - 1) * 100).toFixed(1)
        : "0";

    const totalDurationInYears = indexData.length > 0
        ? ((new Date(indexData[indexData.length - 1].date).getTime() -
            new Date(indexData[0].date).getTime()) /
            (1000 * 60 * 60 * 24 * 365.25)).toFixed(1)
        : "0";

    const handleStartDateChange = (date: Date | null) => {
        setStartDate(date);
        setSelectedRange('CUSTOM');
        handleDateChange(date, true);
    };

    const handleEndDateChange = (date: Date | null) => {
        setEndDate(date);
        setSelectedRange('CUSTOM');
        handleDateChange(date, false);
    };

    // Add stateParams for sharing
    const stateParams = {
        selectedIndex,
        displayMode,
        selectedRange,
        startDate: startDate && !isNaN(startDate.getTime()) ? startDate.toISOString() : null,
        endDate: endDate && !isNaN(endDate.getTime()) ? endDate.toISOString() : null
    };

    if (error) return <div>Error: {(error as Error).message}</div>;

    return (
        <Box sx={{ display: "flex", my: 2 }}>
            <Box sx={{ width: "100%", height: "100%" }}>
                <Box
                    sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 1 }}
                >
                    <Box sx={{ flex: { xs: "1", md: "0 0 400px" }, display: "flex", flexDirection: "column" }}>
                        <ToggleButtonGroup
                            value={selectedIndex}
                            exclusive
                            onChange={handleIndexChange}
                            sx={{
                                paddingTop: 1,
                                "& .MuiToggleButton-root:not(.Mui-selected)": { color: `${theme.palette.text.primary}80` },
                                "& .MuiToggleButton-root.Mui-selected": {
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                    "&:hover": {
                                        backgroundColor: theme.palette.primary.dark,
                                    }
                                },
                            }}
                        >
                            {indices.map((index) => (
                                <ToggleButton
                                    key={index.ticker}
                                    value={index.ticker}
                                    sx={{
                                        boxShadow: 3,
                                        width: "100%",
                                        whiteSpace: "normal",
                                        lineHeight: "1.2"
                                    }}
                                >
                                    {index.label}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>

                        <ToggleButtonGroup
                            value={selectedRange}
                            exclusive
                            onChange={(event, newRange) => {
                                if (newRange !== null) {
                                    setSelectedRange(newRange);
                                    const today = new Date();
                                    let newStartDate = new Date();

                                    switch (newRange) {
                                        case '1M':
                                            newStartDate.setMonth(today.getMonth() - 1);
                                            break;
                                        case '6M':
                                            newStartDate.setMonth(today.getMonth() - 6);
                                            break;
                                        case 'YTD':
                                            newStartDate = new Date(today.getFullYear(), 0, 1);
                                            break;
                                        case '1Y':
                                            newStartDate.setFullYear(today.getFullYear() - 1);
                                            break;
                                        case '5Y':
                                            newStartDate.setFullYear(today.getFullYear() - 5);
                                            break;
                                        case 'MAX':
                                            const maxDate = indices.find(i => i.ticker === selectedIndex)?.maxDate;
                                            newStartDate = maxDate ? new Date(maxDate) : new Date('1900-01-01');
                                            break;
                                        case 'CUSTOM':
                                            return;
                                    }

                                    // Update dates directly instead of using handleStartDateChange/handleEndDateChange
                                    setStartDate(newStartDate);
                                    setEndDate(today);
                                    handleDateChange(newStartDate, true);
                                    handleDateChange(today, false);
                                }
                            }}
                            sx={{
                                paddingTop: 1,
                                "& .MuiToggleButton-root": {
                                    flex: 1,
                                    padding: '4px 8px',
                                },
                                "& .MuiToggleButton-root:not(.Mui-selected)": {
                                    color: `${theme.palette.text.primary}80`  // 80 is hex for 0.5 opacity
                                },
                                "& .MuiToggleButton-root.Mui-selected": {
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                    "&:hover": {
                                        backgroundColor: theme.palette.primary.dark,
                                    }
                                },
                            }}
                        >
                            <ToggleButton value="1M" sx={{ boxShadow: 3 }}>1M</ToggleButton>
                            <ToggleButton value="6M" sx={{ boxShadow: 3 }}>6M</ToggleButton>
                            <ToggleButton value="YTD" sx={{ boxShadow: 3 }}>ÅTD</ToggleButton>
                            <ToggleButton value="1Y" sx={{ boxShadow: 3 }}>1Y</ToggleButton>
                            <ToggleButton value="5Y" sx={{ boxShadow: 3 }}>5Y</ToggleButton>
                            <ToggleButton value="MAX" sx={{ boxShadow: 3 }}>Maks</ToggleButton>
                            <ToggleButton value="CUSTOM" sx={{ boxShadow: 3 }}>Tilpasset</ToggleButton>
                        </ToggleButtonGroup>


                        <Box>
                            <FBDatePicker title="Start dato" icon={TodayOutlinedIcon} value={startDate} setValue={handleStartDateChange} />
                            <FBDatePicker title="Slut dato" icon={EventOutlinedIcon} value={endDate} setValue={handleEndDateChange} />
                        </Box>

                        <ToggleButtonGroup
                            value={displayMode}
                            exclusive
                            onChange={handleDisplayModeChange}
                            sx={{
                                paddingTop: 1,
                                "& .MuiToggleButton-root:not(.Mui-selected)": { color: `${theme.palette.text.primary}80` },
                                "& .MuiToggleButton-root.Mui-selected": {
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                    "&:hover": {
                                        backgroundColor: theme.palette.primary.dark,
                                    }
                                },
                            }}
                        >
                            <ToggleButton value="value" sx={{ boxShadow: 3, width: "100%" }}>
                                Værdi
                            </ToggleButton>
                            <ToggleButton value="index" sx={{ boxShadow: 3, width: "100%" }}>
                                Indeks
                            </ToggleButton>
                        </ToggleButtonGroup>

                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            {/* Summary Box */}
                            <SummaryBox
                                mainValue={`${totalPercentageChange}%`}
                                title={`${indices.find(i => i.ticker === selectedIndex)?.label || ""} har ændret sig`}
                                mainSubtitle={indexData.length > 0 ? `over ${totalDurationInYears} år` : ""}
                                secondaryValues={[
                                    {
                                        value: `${(Number(totalPercentageChange) / Number(totalDurationInYears)).toFixed(1)}%`,
                                        label: "Gennemsnitlig årlig afkast",
                                    },
                                    {
                                        value: indexData.length > 0
                                            ? `${(100 * (Math.pow(indexData[indexData.length - 1].close / indexData[0].close,
                                                1 / Number(totalDurationInYears)) - 1)).toFixed(1)}%`
                                            : "0%",
                                        label: "CAGR",
                                    },
                                ]}
                                sx={{ opacity: isLoading ? 0.3 : 1 }}
                                share={true}
                                stateParams={stateParams}
                            />
                            {/* Graph */}
                            <Box>
                                <IndexDisplayGraph
                                    data={indexData}
                                    isLoading={isLoading}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
