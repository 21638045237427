import { Box, Typography, useTheme, SxProps, Theme, Modal } from "@mui/material";
import IosShareIcon from '@mui/icons-material/IosShare';
import { useState } from "react";
import { ShareModal } from './ShareModal';

interface SummaryBoxProps {
  title: string;
  mainValue: string;
  mainSubtitle?: string;
  secondaryValues?: Array<{
    label: string;
    value: string;
    percentage?: number;
  }>;
  sx?: SxProps<Theme>;
  share?: boolean;
  stateParams?: Record<string, string | number | null>;
  warning?: string;
}

export function SummaryBox({
  title,
  mainValue,
  mainSubtitle,
  secondaryValues,
  sx,
  share = false,
  stateParams,
  warning,
}: SummaryBoxProps) {
  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Box
        onClick={() => share && setModalOpen(true)}
        sx={{
          marginTop: 1,
          py: 2,
          px: 3,
          borderRadius: 1,
          background: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          boxShadow: `0 4px 8px rgba(0, 0, 0, 0.3), 
            inset 1px -1px 3px rgba(0, 0, 0, 0.3),
            inset -2px 2px 4px rgba(255, 255, 255, 0.3)`,
          height: { xs: "100%", sm: "112px" },
          cursor: share ? 'pointer' : 'default',
          ...sx
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: 0, sm: 3 },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Box>
              <Typography
                variant="caption"
                sx={{
                  color: theme.palette.primary.contrastText,
                  opacity: 0.8,
                  textTransform: "uppercase",
                }}
              >
                {title}
              </Typography>
              <Box>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  sx={{
                    fontSize: {
                      xs: "h4.fontSize",
                      sm: "h5.fontSize",
                    },
                    "@media screen and (min-width: 900px) and (max-width: 1000px)":
                    {
                      fontSize: "1.5rem",
                    },
                  }}
                >
                  {mainValue}
                </Typography>
              </Box>
              <Typography
                variant="caption"
                sx={{
                  color: theme.palette.primary.contrastText,
                  opacity: 0.7,
                }}
              >{mainSubtitle || '\u00A0'}</Typography>
            </Box>
          </Box>
          {secondaryValues && (
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: 2,
                flex: 1,
              }}
            >
              {secondaryValues.map((item, index) => (
                <Box key={index}>
                  <Typography
                    variant="caption"
                    sx={{
                      color: theme.palette.primary.contrastText,
                      opacity: 0.7,
                      textTransform: "uppercase",
                    }}
                  >
                    {item.label}
                  </Typography>
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        color: theme.palette.primary.contrastText,
                        opacity: 0.7,
                        "@media screen and (min-width: 900px) and (max-width: 1000px)":
                        {
                          fontSize: "1rem",
                        },
                      }}
                    >
                      {item.value}
                    </Typography>
                  </Box>
                  {item.percentage !== undefined && (
                    <Typography
                      variant="caption"
                      sx={{
                        color: theme.palette.primary.contrastText,
                        opacity: 0.7,
                      }}
                    >
                      ({item.percentage.toFixed(1)}% af værdien)
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
          )}
          {share && (
            <Box sx={{
              display: "flex",
              flexDirection: { xs: "row", sm: "column" },
              alignItems: { xs: "flex-start", sm: "center" },
              justifyContent: { xs: "flex-start", sm: "center" },
              color: theme.palette.primary.contrastText,
              marginLeft: { xs: 0, sm: "auto" },
              marginTop: { xs: 2, sm: 0 },
              opacity: 0.7,
            }}>
              <IosShareIcon />
              <Typography
                variant="caption"
                sx={{
                  color: theme.palette.primary.contrastText,
                  opacity: 0.7,
                  mt: { xs: 1, sm: 0.5 },
                  ml: { xs: 1, sm: 0 },
                }}
              >
                Del dine tal
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <ShareModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        stateParams={stateParams}
        warning={warning}
      />
    </>
  );
}
