import { useEffect, useState } from "react";
import { FBSlider } from "../FBSlider";
import { Box } from "@mui/material";
import { formatCurrency } from "../../utils/formatters";
import { SummaryBox } from "./SummaryBox";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import { useFormLogger } from "../../hooks/useFormLogger";
import { useSearchParams } from "react-router-dom";
import { FutureInflationGraph } from "./FutureInflationGraph";

export function FutureInflationCalculator() {
  const [searchParams] = useSearchParams();

  const INITIAL_VALUES = {
    startValue: 100000,
    inflation: 2.0,
    years: 15,
  };

  const [startValue, setStartValue] = useState<number>(
    Number(searchParams.get('startValue')) || INITIAL_VALUES.startValue
  );
  const [inflation, setInflation] = useState<number>(
    Number(searchParams.get('inflation')) || INITIAL_VALUES.inflation
  );
  const [years, setYears] = useState<number>(
    Number(searchParams.get('years')) || INITIAL_VALUES.years
  );

  useFormLogger({
    formName: 'future_inflation_calculator',
    values: {
      startValue,
      inflation,
      years
    }
  });

  const [calculationValues, setCalculationValues] = useState({
    startValue: 0,
    inflation: 0,
    years: 0,
  });

  const [adjustedAmount, setAdjustedAmount] = useState<number>(0);

  useEffect(() => {
    const handler = setTimeout(() => {
      setCalculationValues({
        startValue,
        inflation,
        years,
      });

      // Calculate future value with compound inflation
      const futureValue = startValue / Math.pow(1 + inflation / 100, years);
      setAdjustedAmount(futureValue);
    }, 300);

    return () => clearTimeout(handler);
  }, [startValue, inflation, years]);

  const stateParams = Object.entries({
    startValue,
    inflation,
    years,
  }).reduce((acc, [key, value]) => {
    if (value !== INITIAL_VALUES[key as keyof typeof INITIAL_VALUES]) {
      acc[key] = value;
    }
    return acc;
  }, {} as Record<string, number>);

  return (
    <Box sx={{ display: "flex", my: 2 }}>
      <Box sx={{ width: "100%", height: "100%" }}>
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 1 }}>
          <Box sx={{ flex: { xs: "1", md: "0 0 400px" }, display: "flex", flexDirection: "column" }}>
            <FBSlider
              title={"Beløb (tryk på tallet for at indtaste)"}
              valuePostFix=" kr."
              min={0}
              max={1000000}
              steps={1000000 / 1000}
              value={startValue}
              setValue={setStartValue}
              icon={PaidOutlinedIcon}
            />
            <FBSlider
              title={"Årlig inflation"}
              valuePostFix="%"
              min={0}
              max={10}
              steps={0.1}
              value={inflation}
              setValue={setInflation}
              formatter={(value) => value.toFixed(1)}
              icon={PercentOutlinedIcon}
            />
            <FBSlider
              title={"Antal år"}
              valuePostFix=" år"
              min={1}
              max={30}
              steps={1}
              value={years}
              setValue={setYears}
              icon={ScheduleOutlinedIcon}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <SummaryBox
                title={`${formatCurrency(calculationValues.startValue)} kr. i købekraft i dag svarer til`}
                mainValue={`${formatCurrency(adjustedAmount)} kr.`}
                mainSubtitle={`i købekraft om ${calculationValues.years} år`}
                secondaryValues={[
                  {
                    label: "Et tab i købekraft på",
                    value: `${formatCurrency((1 - adjustedAmount / startValue) * 100, 1, 1)}%`,
                  },
                  {
                    label: "Årlig inflation",
                    value: `${formatCurrency(inflation, 1, 1)}%`,
                  },
                ]}
                share={true}
                stateParams={stateParams}
              />
              <Box>
                <FutureInflationGraph
                  startValue={calculationValues.startValue}
                  inflation={calculationValues.inflation}
                  years={calculationValues.years}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
} 