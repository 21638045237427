import { useFetchAbisList, useFetchLatestUpdatedAt } from "../../services/BackendService";
import { useState, useEffect, useMemo } from 'react';
import { Messages } from "../MessageBox";
import { Box, useTheme, Button, Typography } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';
import FBLoading from "../FBLoading";
import { AbisList } from "../../services/BackendTypes";


export default function AbisSearchTool() {
    const { data: latestUpdatedAt } = useFetchLatestUpdatedAt("abis_list");
    const { data, isLoading, error } = useFetchAbisList({
        staleTime: 1000 * 60 * 60 * 24, // 1 day
        select: (data: AbisList[]) => {
            localStorage.setItem('abis-data', JSON.stringify({
                timestamp: Date.now(),
                data: data
            }));
            return data;
        },
        initialData: () => {
            const cached = localStorage.getItem('abis-data');
            if (cached && latestUpdatedAt) {
                const { timestamp, data } = JSON.parse(cached);
                // Only use cache if it's newer than latest server update
                if (new Date(latestUpdatedAt.last_updated_at).getTime() < timestamp) {
                    return data;
                }
            }
            return undefined;
        }
    });
    const theme = useTheme();
    const [filteredData, setFilteredData] = useState(data);
    const [notFoundIsins, setNotFoundIsins] = useState<string[]>([]);
    const [searchText, setSearchText] = useState('');

    const handleSearch = () => {
        if (!data) return;
        if (!searchText.trim()) {
            setFilteredData(data.sort((a, b) => a.isin.localeCompare(b.isin)));
            setNotFoundIsins([]);
            return;
        }

        const isins = searchText
            .split(/[\s,;\t]+/)
            .map(isin => isin.trim())
            .filter(isin => isin.length > 0);

        const filtered = data?.filter(item =>
            isins.some(searchIsin =>
                item.isin.toLowerCase().includes(searchIsin.toLowerCase())
            )
        );

        const foundIsins = filtered?.map(item => item.isin.toLowerCase()) ?? [];
        const notFound = isins.filter(searchIsin =>
            !foundIsins.some(foundIsin =>
                foundIsin.includes(searchIsin.toLowerCase())
            )
        );

        setFilteredData(filtered);
        setNotFoundIsins(notFound);
    };

    // Memoize the table rows to prevent re-rendering on every input change
    const memoizedTableRows = useMemo(() =>
        filteredData?.map((item) => (
            <TableRow
                key={item.isin}
                hover
                sx={{
                    '&:nth-of-type(even)': {
                        backgroundColor: "grey.50"
                    },
                    color: item.removed_on ? 'error.main' : 'inherit',
                    '& .MuiTableCell-root': {
                        color: 'inherit'
                    }
                }}
            >
                <TableCell>{item.isin}</TableCell>
                <TableCell sx={{ width: '300px' }}>{item.navn_andelsklasse}</TableCell>
                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{item.skattemaessigt_hjemsted}</TableCell>
                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{item.forste_registreringsaar}</TableCell>
                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                    {item.added_on ? new Intl.DateTimeFormat('da-DK', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                    }).format(new Date(item.added_on)).replace('.', '/').replace('.', '-') : ''}
                </TableCell>
                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                    {item.removed_on ? new Intl.DateTimeFormat('da-DK', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                    }).format(new Date(item.removed_on)).replace('.', '/').replace('.', '-') : ''}
                </TableCell>
            </TableRow>
        )),
        [filteredData] // Only re-compute when filteredData changes
    );

    if (isLoading) return <FBLoading />;
    if (error) return <Messages.Error message="Error fetching data" />;

    return (
        <Box>
            {latestUpdatedAt && (
                <Box>
                    <Typography variant="subtitle2" fontWeight={200} fontSize={14}>
                        (Listen er opdateret {
                            new Intl.DateTimeFormat('da-DK', {
                                day: '2-digit',
                                month: 'long',
                                year: 'numeric'
                            }
                            ).format(new Date(latestUpdatedAt.last_updated_at))
                        } fra <u><a href="https://skat.dk/erhverv/ekapital/vaerdipapirer/beviser-og-aktier-i-investeringsforeninger-og-selskaber-ifpa" target="_blank" rel="noopener noreferrer">skat.dk</a></u>)
                    </Typography>
                </Box>
            )}
            <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: { xs: 'stretch', sm: 'flex-start' },
                gap: 1,
                mb: 3
            }}>

                <TextField
                    sx={{
                        width: {
                            xs: '100%',
                            sm: '600px'
                        },
                        '& .MuiOutlinedInput-root': {
                            backgroundColor: theme.palette.primary.main,
                            '& textarea': {
                                resize: 'vertical',
                                overflow: 'auto'
                            }
                        }
                    }}
                    multiline
                    rows={6}
                    placeholder="Indsæt en eller flere ISIN-numre (efterlad blank for hele listen)"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    variant="outlined"
                    slotProps={{
                        input: {
                            sx: {
                                color: theme.palette.primary.contrastText,
                                borderRadius: 2
                            },
                        },
                    }}
                />
                <Button
                    variant="contained"
                    onClick={handleSearch}
                    sx={{
                        height: '40px',
                        px: 4,
                        width: { xs: '100%', sm: 'auto' }
                    }}
                >
                    SØG
                </Button>
            </Box>

            {notFoundIsins.length > 0 && (
                <Box sx={{ mb: 3, color: 'error.main' }}>
                    <Typography variant="subtitle1" fontWeight="bold">
                        Disse ISIN numre er <u>ikke</u> på listen:
                    </Typography>
                    <Box component="pre" sx={{ mt: 1, p: 1, backgroundColor: 'error.light', borderRadius: 1 }}>
                        {notFoundIsins.join('\n')}
                    </Box>
                </Box>
            )}

            {filteredData && filteredData.some(item => !item.removed_on) && (
                <>
                    <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 2 }}>
                        Disse ISIN numre er på listen:
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{ backgroundColor: theme.palette.accent.main }}>
                                    <TableCell>ISIN</TableCell>
                                    <TableCell sx={{ width: '100px' }}>Navn</TableCell>
                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>Skattemæssigt Hjemsted</TableCell>
                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>Første Registreringsår</TableCell>
                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>Tilføjet</TableCell>
                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>Fjernet</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {memoizedTableRows?.filter(row => row.props['sx'].color !== 'error.main')}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}

            {filteredData && filteredData.some(item => item.removed_on) && (
                <>
                    <Typography variant="subtitle1" fontWeight="bold" sx={{ mt: 4, mb: 2, color: 'error.main' }}>
                        Disse ISIN numre er blevet fjernet fra listen:
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{ backgroundColor: 'error.light' }}>
                                    <TableCell>ISIN</TableCell>
                                    <TableCell sx={{ width: '300px' }}>Navn</TableCell>
                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>Skattemæssigt Hjemsted</TableCell>
                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>Første Registreringsår</TableCell>
                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>Tilføjet</TableCell>
                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>Fjernet</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {memoizedTableRows?.filter(row => row.props['sx'].color === 'error.main')}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </Box>
    );
}