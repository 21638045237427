import { Box, useTheme } from "@mui/material";
import { FBSlider } from "../FBSlider";
import { useEffect, useState } from "react";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import FBDatePicker from "../FBDatePicker";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import { SummaryBox } from "./SummaryBox";
import { CAGRGraph } from "./CAGRGraph";
import { useFormLogger } from "../../hooks/useFormLogger";
import { useSearchParams } from "react-router-dom";

export default function CAGRCalculator() {
  const [searchParams] = useSearchParams();

  const INITIAL_VALUES = {
    startValue: 100,
    endValue: 500,
    totalReturn: 100,
    inputMethod: 'return' as const,
    startDate: new Date("04/18/2014"),
    endDate: new Date(),
  };

  const [startValue, setStartValue] = useState(Number(searchParams.get('startValue')) || INITIAL_VALUES.startValue);
  const [endValue, setEndValue] = useState(Number(searchParams.get('endValue')) || INITIAL_VALUES.endValue);
  const [totalReturn, setTotalReturn] = useState(Number(searchParams.get('totalReturn')) || INITIAL_VALUES.totalReturn);
  const [inputMethod, setInputMethod] = useState<'values' | 'return'>(
    (searchParams.get('inputMethod') as 'values' | 'return') || INITIAL_VALUES.inputMethod
  );
  const [maxMoneyValue, setMaxMoneyValue] = useState(1000);
  const [maxReturnValue, setMaxReturnValue] = useState(1000);
  const [startDate, setStartDate] = useState<Date | null>(() => {
    const startDateParam = searchParams.get('startDate');
    if (startDateParam) {
      const date = new Date(startDateParam);
      return !isNaN(date.getTime()) ? date : INITIAL_VALUES.startDate;
    }
    return INITIAL_VALUES.startDate;
  });
  const [endDate, setEndDate] = useState<Date | null>(() => {
    const endDateParam = searchParams.get('endDate');
    if (endDateParam) {
      const date = new Date(endDateParam);
      return !isNaN(date.getTime()) ? date : INITIAL_VALUES.endDate;
    }
    return INITIAL_VALUES.endDate;
  });
  const [duration, setDuration] = useState(0);
  const [cagr, setCagr] = useState(0);

  // Add form logging - only tracking user inputs
  useFormLogger({
    formName: 'cagr_calculator',
    values: {
      totalReturn,
      inputMethod,
      startDate: startDate && !isNaN(startDate.getTime()) ? startDate.toISOString() : null,
      endDate: endDate && !isNaN(endDate.getTime()) ? endDate.toISOString() : null,
    }
  });

  const handleInputMethodChange = (
    event: React.MouseEvent<HTMLElement>,
    newInputMethod: 'values' | 'return' | null,
  ) => {
    if (newInputMethod !== null) {
      setInputMethod(newInputMethod);
    }
  };

  const theme = useTheme();

  // First useEffect: Update totalReturn when start/end values change
  useEffect(() => {
    if (inputMethod === 'values') {
      setTotalReturn(Math.round(((endValue - startValue) / startValue) * 100));
    }
  }, [startValue, endValue, inputMethod]);

  // Second useEffect: Update endValue when totalReturn changes
  useEffect(() => {
    if (inputMethod === 'return') {
      setEndValue(Math.round(startValue * (1 + totalReturn / 100)));
    }
  }, [totalReturn, startValue, inputMethod]);

  // Update maxMoneyValue when values exceed current max
  useEffect(() => {
    const highestValue = Math.max(startValue, endValue);
    if (highestValue > maxMoneyValue) {
      setMaxMoneyValue(highestValue);
    }
  }, [startValue, endValue, maxMoneyValue]);

  // Update maxReturnValue when totalReturn exceeds current max
  useEffect(() => {
    if (totalReturn > maxReturnValue) {
      setMaxReturnValue(totalReturn);
    }
  }, [totalReturn, maxReturnValue]);

  useEffect(() => {
    const handler = setTimeout(() => {
      // Validate that both dates are valid and startDate is before endDate
      if (startDate && endDate && startDate instanceof Date && endDate instanceof Date && !isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
        const yearsBetween = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24 * 365.25);
        setDuration(yearsBetween);

        // Only calculate CAGR if duration is positive
        if (yearsBetween > 0) {
          setCagr(100 * (Math.pow(endValue / startValue, 1 / yearsBetween) - 1));
        } else {
          setCagr(0);
        }
      } else {
        // Reset values if dates are invalid
        setDuration(0);
        setCagr(0);
      }
    }, 300);

    return () => clearTimeout(handler);
  }, [startDate, endDate, startValue, endValue]);

  // Update stateParams to safely handle dates
  const stateParams = Object.entries({
    startValue,
    endValue,
    totalReturn,
    inputMethod,
    startDate: startDate && !isNaN(startDate.getTime()) ?
      `${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()}` : null,
    endDate: endDate && !isNaN(endDate.getTime()) ?
      `${endDate.getDate()}/${endDate.getMonth() + 1}/${endDate.getFullYear()}` : null,
  }).reduce((acc, [key, value]) => {
    if (key === 'startDate' || key === 'endDate') {
      const initialDate = INITIAL_VALUES[key as keyof typeof INITIAL_VALUES] as Date;
      if (value && value !== initialDate.toISOString()) {
        acc[key] = value;
      }
    } else if (value !== INITIAL_VALUES[key as keyof typeof INITIAL_VALUES]) {
      acc[key] = value as string | number;
    }
    return acc;
  }, {} as Record<string, number | string>);

  return (
    <Box sx={{ display: "flex", my: 2 }}>
      <Box sx={{ width: "100%", height: "100%" }}>
        <Box
          sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 1 }}
        >
          <Box sx={{ flex: { xs: "1", md: "0 0 400px" }, display: "flex", flexDirection: "column" }}>
            <FBDatePicker title="Start dato" icon={TodayOutlinedIcon} value={startDate} setValue={setStartDate} />
            <FBDatePicker title="Slut dato" icon={EventOutlinedIcon} value={endDate} setValue={setEndDate} />

            <ToggleButtonGroup
              value={inputMethod}
              exclusive
              onChange={handleInputMethodChange}
              sx={{
                paddingTop: 2,
                "& .MuiToggleButton-root:not(.Mui-selected)": { color: theme.palette.secondary.main },
                "& .MuiToggleButton-root.Mui-selected": {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                  }
                },
              }}
            >
              <ToggleButton value="return" sx={{ boxShadow: 3 }}>Total afkast i procent</ToggleButton>
              <ToggleButton value="values" sx={{ boxShadow: 3 }}>Start/Slut værdier</ToggleButton>
            </ToggleButtonGroup>

            {inputMethod === 'values' ? (
              <>

                <FBSlider
                  title={"Start værdi"}
                  valuePostFix=" kr."
                  min={0}
                  max={maxMoneyValue}
                  steps={5}
                  value={startValue}
                  setValue={setStartValue}
                  icon={SavingsOutlinedIcon}
                />
                <FBSlider
                  title={"Slut værdi"}
                  valuePostFix=" kr."
                  min={0}
                  max={maxMoneyValue}
                  steps={5}
                  value={endValue}
                  setValue={setEndValue}
                  icon={SavingsOutlinedIcon}
                />
              </>
            ) : (
              <FBSlider
                title={"Total afkast"}
                valuePostFix=" %"
                min={0}
                max={maxReturnValue}
                steps={1}
                value={totalReturn}
                setValue={setTotalReturn}
                icon={PercentOutlinedIcon}
              />
            )}
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {/* Summary Box */}
              <SummaryBox
                mainValue={`${cagr.toFixed(2)}%`}
                title={`CAGR over ${duration.toFixed(1)} år`}
                secondaryValues={[
                  {
                    value: `${(totalReturn.toFixed(2))} %`,
                    label: "Total afkast"
                  },
                  {
                    value: `${(totalReturn / duration).toFixed(2)} %`,
                    label: "Gennemsnitligt afkast"
                  }
                ]}
                share={true}
                stateParams={stateParams as Record<string, number>}
              />
              {/* Graph */}
              <Box>
                <CAGRGraph startValue={startValue} totalYears={duration} interest={cagr} inputMethod={inputMethod} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
