import { useCallback, useState, useEffect } from "react";
import TopNavbar from "./layout/TopNavBar";
import { Box, AppBar, useMediaQuery, Theme, Drawer, IconButton } from "@mui/material";
import { Logo } from "./layout/Logo";
import Sidebar from "./layout/Sidebar";
import { Footer } from "./layout/Footer";
import { Outlet, useMatches } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
const drawerWidth = 240;

// Add this interface above the Root component
interface SEOHandle {
  seo?: {
    title: string;
    description: string;
  };
}

export default function Root() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const matches = useMatches();
  const seo = (matches[matches.length - 1].handle as SEOHandle)?.seo;

  useEffect(() => {
    if (seo) {
      document.title = seo.title;
      // Update meta description
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', seo.description);
      } else {
        const meta = document.createElement('meta');
        meta.name = 'description';
        meta.content = seo.description;
        document.head.appendChild(meta);
      }
    }
  }, [seo]);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((prevState) => !prevState);
  }, []);

  return (
    <>
      {!isMobile && (<TopNavbar />)}
      <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
        <AppBar position="sticky">
          {isMobile && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  paddingTop: 0,
                  paddingLeft: 2,
                  paddingRight: 2,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <Logo size={150} />
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </Box>
          )}
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { xs: drawerWidth }, flexShrink: { xs: 0 } }}
        >
          {isMobile && (
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              <Sidebar open={mobileOpen} />
            </Drawer>
          )}
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            maxWidth: "1200px",
            mx: "auto",
            width: "100%",
            padding: { xs: 3, sm: 4 },
          }}
        >
          <Outlet />
        </Box>
        <Footer />
      </Box>
    </>
  );
}
