import { useQuery } from "@tanstack/react-query";
import { ApiResponse, ApiService } from "./ApiService";
import {
  Article,
  ArticlesPageData,
  Author,
  CalculatorsPageData,
  HeroSection,
  Navigation,
} from "./StrapiTypes";
import qs from "qs";

export function useFetchNavData(options?: { enabled?: boolean }) {
  const query = qs.stringify({
    populate: {
      topNav: {
        populate: "*",
      },
    },
  });

  return useQuery({
    queryKey: ["navData"],
    queryFn: async () => {
      const response = await ApiService.makeRequest<
        ApiResponse<{ topNav: Navigation }>
      >(`/global?${query}`);
      return response.data;
    },
    staleTime: Infinity,
    gcTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: options?.enabled !== false,
  });
}

export function useFetchHomePageData() {
  const query = qs.stringify({
    populate: {
      heroSection: {
        populate: "*",
      },
    },
  });

  return useQuery({
    queryKey: ["homePageData"],
    queryFn: async () => {
      const response = await ApiService.makeRequest<
        ApiResponse<{ heroSection: HeroSection }>
      >(`/home-page?${query}`);
      return response.data;
    },
  });
}

export function useFetchArticleBySlug(slug: string) {
  const query = qs.stringify({
    filters: {
      slug: { $eq: slug },
    },
    fields: ["title", "description", "subheading", "publishedAt", "showAuthors", "createdAt", "slug"],
    populate: {
      authors: {
        fields: ["name"],
        populate: {
          avatar: {
            populate: "*",
          },
        },
      },
      blocks: {
        populate: "*",
      },
      coverImage: {
        populate: "*",
      },
      seo_metadata: {
        populate: "*",
      },
      categories: {
        populate: "*",
      },
    },
  });

  return useQuery({
    queryKey: ["articleBySlug", slug],
    queryFn: async () => {
      const response = await ApiService.makeRequest<ApiResponse<[Article]>>(
        `/articles?${query}`
      );
      return response.data;
    },
  });
}

function useFetchPageData<T extends object>(pageType: 'calculators' | 'data') {
  const query = qs.stringify({
    populate: {
      calculatorLinks: {
        populate: "*",
      },
    },
  });

  return useQuery({
    queryKey: [`${pageType}PageData`],
    queryFn: async () => {
      const response = await ApiService.makeRequest<ApiResponse<T>>(
        `/${pageType}-page?${query}`
      );
      return response.data;
    },
  });
}

export function useFetchCalculatorsPageData() {
  return useFetchPageData<CalculatorsPageData>('calculators');
}

export function useFetchDataPageData() {
  return useFetchPageData<CalculatorsPageData>('data');
}

export function useFetchAuthors() {
  const query = qs.stringify({
    populate: "*",
  });

  return useQuery({
    queryKey: ["authors"],
    queryFn: async () => {
      const response = await ApiService.makeRequest<ApiResponse<[Author]>>(`/authors?${query}`);
      return response.data;
    },
  });
}

export function useFetchArticlesCards() {
  const query = qs.stringify({
    filters: {
      slug: {
        $eq: 'articles',
      },
    },
    populate: {
      articles: {
        populate: {
          coverImage: {
            populate: "*",
          },
          linkImage: {
            populate: "*",
          },
          authors: {
            populate: "*",
          },
        },
        sort: ['createdAt:desc'],
      },
    },
    status: 'published',
  });

  return useQuery({
    queryKey: ["articlesCards"],
    queryFn: async () => {
      const response = await ApiService.makeRequest<ArticlesPageData>(
        `/categories?${query}`
      );
      return response.data;
    },
  });
}
