import { CalculatorLinksSkeleton } from "../../components/skeletons/CalculatorLinksSkeleton";
import { HeroSection } from "./HeroSections";
import {
  useFetchCalculatorsPageData,
  useFetchHomePageData,
} from "../../services/StrapiService";
import { CalculatorLinks } from "./CalculatorLinks";
import { HeroSectionSkeleton } from "../../components/skeletons/HeroSectionSkeleton";

export default function HomePage() {
  const homePageQuery = useFetchHomePageData();
  const calculatorsPageQuery = useFetchCalculatorsPageData();

  return (
    <>
      {/* Hero Section with independent loading */}
      {homePageQuery.isLoading ? (
        <HeroSectionSkeleton />
      ) : (
        homePageQuery.data?.heroSection && (
          <HeroSection heroSection={homePageQuery.data.heroSection} />
        )
      )}

      {/* Calculator Links with independent loading */}
      {calculatorsPageQuery.isLoading ? (
        <CalculatorLinksSkeleton skeletonCount={6} />
      ) : (
        <CalculatorLinks
          calculatorLinks={calculatorsPageQuery.data?.calculatorLinks ?? []}
        />
      )}
    </>
  );
}
