import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { FBSlider } from "../FBSlider";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import {
  calculateArpAdjustedReturn,
} from "../../utils/calculations";
import { formatCurrency } from "../../utils/formatters";
import { SummaryBox } from "./SummaryBox";
import { ARPComparisonGraph } from "./ARPComparisonGraph";
import { useFormLogger } from "../../hooks/useFormLogger";
import { useSearchParams } from "react-router-dom";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

interface ArpResult {
  yearlyValues: number[];
  yearlyFees: number[];
}

export function ARPCompareCalculator() {
  const [searchParams] = useSearchParams();

  const INITIAL_VALUES = {
    startValue: 100000,
    monthlyDeposit: 2500,
    interest: 7.0,
    totalYears: 30,
    arpFund1: 0.5,
    arpFund2: 1.5,
  };

  const [startValue, setStartValue] = useState<number>(Number(searchParams.get('startValue')) || INITIAL_VALUES.startValue);
  const [monthlyDeposit, setMonthlyDeposit] = useState<number>(Number(searchParams.get('monthlyDeposit')) || INITIAL_VALUES.monthlyDeposit);
  const [interest, setInterest] = useState<number>(Number(searchParams.get('interest')) || INITIAL_VALUES.interest);
  const [totalYears, setTotalYears] = useState<number>(Number(searchParams.get('totalYears')) || INITIAL_VALUES.totalYears);
  const [arpFund1, setArpFund1] = useState<number>(Number(searchParams.get('arpFund1')) || INITIAL_VALUES.arpFund1);
  const [arpFund2, setArpFund2] = useState<number>(Number(searchParams.get('arpFund2')) || INITIAL_VALUES.arpFund2);

  const stateParams = Object.entries({
    startValue,
    monthlyDeposit,
    interest,
    totalYears,
    arpFund1,
    arpFund2,
  }).reduce((acc, [key, value]) => {
    if (value !== INITIAL_VALUES[key as keyof typeof INITIAL_VALUES]) {
      acc[key] = value as number;
    }
    return acc;
  }, {} as Record<string, number>);

  useFormLogger({
    formName: 'arp_compare_calculator',
    values: {
      startValue,
      monthlyDeposit,
      interest,
      totalYears,
      arpFund1,
      arpFund2
    }
  });

  const [result1, setResult1] = useState<ArpResult>({
    yearlyValues: [],
    yearlyFees: [],
  });
  const [result2, setResult2] = useState<ArpResult>({
    yearlyValues: [],
    yearlyFees: [],
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      const calculatedResult1 = calculateArpAdjustedReturn(
        totalYears,
        arpFund1,
        startValue,
        interest,
        monthlyDeposit
      );
      const calculatedResult2 = calculateArpAdjustedReturn(
        totalYears,
        arpFund2,
        startValue,
        interest,
        monthlyDeposit
      );

      setResult1(calculatedResult1);
      setResult2(calculatedResult2);
    }, 300);
    return () => clearTimeout(handler);
  }, [arpFund1, arpFund2, interest, startValue, totalYears, monthlyDeposit]);

  return (
    <Box
      sx={{
        display: "flex",
        my: 2,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 1,
          }}
        >
          <Box
            sx={{
              flex: { xs: "1", md: "0 0 400px" },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FBSlider
              title={"Start"}
              valuePostFix=" kr."
              min={0}
              max={1000000}
              steps={1000000 / 50}
              value={startValue}
              setValue={setStartValue}
              icon={SavingsOutlinedIcon}
            />
            <FBSlider
              title={"Månedlig indskud"}
              valuePostFix=" kr."
              min={0}
              max={10000}
              value={monthlyDeposit}
              setValue={setMonthlyDeposit}
              icon={CalendarMonthOutlinedIcon}
            />
            <Box
              sx={{
                display: "flex",
                gap: 1,
                width: "100%"
              }}
            >
              <FBSlider
                title={"ÅO fond 1"}
                valuePostFix="%"
                min={0}
                max={3}
                steps={0.1}
                value={arpFund1}
                setValue={setArpFund1}
                formatter={(value: number) => value.toFixed(1)}
                icon={PercentOutlinedIcon}
                sx={{ flex: 1 }}
              />
              <FBSlider
                title={"ÅO fond 2"}
                valuePostFix="%"
                min={0}
                max={3}
                steps={0.1}
                value={arpFund2}
                setValue={setArpFund2}
                formatter={(value: number) => value.toFixed(1)}
                icon={PercentOutlinedIcon}
                sx={{ flex: 1 }}
              />
            </Box>
            <FBSlider
              title={"Afkast"}
              valuePostFix="%"
              min={0}
              max={10}
              steps={0.1}
              value={interest}
              setValue={setInterest}
              formatter={(value: number) => value.toFixed(1)}
              icon={TrendingUpOutlinedIcon}
            />
            <FBSlider
              title={"Tid"}
              valuePostFix=" år"
              min={0}
              max={40}
              steps={1}
              value={totalYears}
              setValue={setTotalYears}
              icon={ScheduleOutlinedIcon}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Summary Box */}
              <SummaryBox
                title="Forskel pga. ÅO"
                mainValue={`${formatCurrency(
                  (result1.yearlyValues[result1.yearlyValues.length - 1] || 0) -
                  (result2.yearlyValues[result2.yearlyValues.length - 1] || 0)
                )} kr.`}
                secondaryValues={[
                  {
                    label: `Slutværdi fond 1 (${arpFund1}%)`,
                    value: `${formatCurrency(
                      result1.yearlyValues[result1.yearlyValues.length - 1] || 0
                    )} kr.`,
                  },
                  {
                    label: `Slutværdi fond 2 (${arpFund2}%)`,
                    value: `${formatCurrency(
                      result2.yearlyValues[result2.yearlyValues.length - 1] || 0
                    )} kr.`,
                  },
                ]}
                share={true}
                stateParams={stateParams}
              />

              {/* Graph */}
              <Box>
                <ARPComparisonGraph
                  yearlyValuesFund1={result1.yearlyValues}
                  yearlyValuesFund2={result2.yearlyValues}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
