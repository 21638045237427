import { Box, Card, useTheme } from "@mui/material";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useMemo } from "react";
import FBLoading from "../FBLoading";

interface NationalBankRatesGraphProps {
  startYear: number;
  endYear: number;
  isLoading: boolean;
  folioRate?: { rate_date: string; rate_value: number }[];
  indskudRate?: { rate_date: string; rate_value: number }[];
  udlanRate?: { rate_date: string; rate_value: number }[];
  diskontoRate?: { rate_date: string; rate_value: number }[];
  colors?: {
    folioRate?: string;
    indskudRate?: string;
    udlanRate?: string;
    diskontoRate?: string;
  };
}

export function NationalBankRatesGraph(props: NationalBankRatesGraphProps) {
  const { startYear, endYear, isLoading, folioRate, indskudRate, udlanRate, diskontoRate, colors } = props;
  const theme = useTheme();

  const graphData = useMemo(() => {
    const startDate = new Date(startYear, 0, 1);
    const endDate = new Date(endYear, 11, 31);

    const filterAndSortData = (data?: { rate_date: string; rate_value: number }[]) => {
      if (!data) return [];
      return data
        .filter(d => {
          const date = new Date(d.rate_date);
          return date >= startDate && date <= endDate;
        })
        .sort((a, b) => new Date(a.rate_date).getTime() - new Date(b.rate_date).getTime())
        .map(d => ({
          date: new Date(d.rate_date),
          rate: d.rate_value
        }));
    };

    // Get all unique dates from all datasets
    const allDates = new Set([
      ...filterAndSortData(folioRate).map(d => d.date.getTime()),
      ...filterAndSortData(indskudRate).map(d => d.date.getTime()),
      ...filterAndSortData(udlanRate).map(d => d.date.getTime()),
      ...filterAndSortData(diskontoRate).map(d => d.date.getTime()),
    ]);

    const sortedDates = Array.from(allDates).sort((a, b) => a - b).map(timestamp => new Date(timestamp));

    // Helper function to interpolate missing values
    const interpolateData = (data: { date: Date; rate: number }[]) => {
      if (data.length === 0) return [];

      const result: (number | undefined)[] = [];
      let currentIndex = 0;

      sortedDates.forEach(date => {
        // Only start interpolating after or at the first data point
        if (date.getTime() < data[0].date.getTime()) {
          result.push(undefined);
          return;
        }

        while (currentIndex < data.length - 1 &&
          date.getTime() > data[currentIndex + 1].date.getTime()) {
          currentIndex++;
        }
        result.push(data[currentIndex].rate);
      });

      return result;
    };

    // Modified return structure to match Recharts format
    return sortedDates.map((date, index) => ({
      date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
      folioRate: folioRate && interpolateData(filterAndSortData(folioRate))[index],
      indskudRate: indskudRate && interpolateData(filterAndSortData(indskudRate))[index],
      udlanRate: udlanRate && interpolateData(filterAndSortData(udlanRate))[index],
      diskontoRate: diskontoRate && interpolateData(filterAndSortData(diskontoRate))[index],
    }));
  }, [folioRate, indskudRate, udlanRate, diskontoRate, startYear, endYear]);

  // Add this new calculation for Y-axis domain
  const yAxisDomain = useMemo(() => {
    const allValues = graphData.flatMap(data => [
      data.folioRate,
      data.indskudRate,
      data.udlanRate,
      data.diskontoRate
    ].filter(val => val !== undefined && val !== null));

    if (allValues.length === 0) return [-4, 12]; // fallback to default if no data

    const minValue = Math.floor(Math.min(...allValues));
    const maxValue = Math.ceil(Math.max(...allValues));

    // Add 10% padding to the range
    const padding = Math.max(1, Math.round((maxValue - minValue) * 0.1));
    return [minValue - padding, maxValue + padding];
  }, [graphData]);

  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          position: 'relative',
          paddingTop: 1,
          height: '100%'
        }}
      >
        <Card sx={{
          width: '100%',
          height: {
            xs: 400,
            sm: 488
          },
          opacity: isLoading ? 0.1 : 1
        }}>
          <Box sx={{
            width: '100%',
            height: '100%',
            opacity: isLoading ? 0.1 : 1
          }}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={graphData}
                margin={{ top: 40, right: 30, left: 20, bottom: 20 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  angle={-45}
                  textAnchor="end"
                  height={70}
                  tickFormatter={(label) => {
                    const [day, month, year] = label.split('/');
                    const date = new Date(Number(year), Number(month) - 1, Number(day));
                    return `${date.getDate()}/${date.getMonth() + 1}-${date.getFullYear()}`;
                  }}
                />
                <YAxis
                  domain={yAxisDomain}
                  tickFormatter={(value) => `${value.toFixed(2)}%`}
                  label={{
                    value: 'Rente',
                    position: 'insideLeft',
                    angle: -90,
                    offset: -10,  // Adds spacing between label and values
                  }}
                />
                <Tooltip
                  labelFormatter={(label) => {
                    const [day, month, year] = label.split('/');
                    const date = new Date(Number(year), Number(month) - 1, Number(day));
                    return `${date.getDate()}/${date.getMonth() + 1}-${date.getFullYear()}`;
                  }}
                  formatter={(value: any, name: string) => {
                    if (value === undefined || value === null) return ['-', ''];
                    return [`${Number(value).toFixed(2)}%`, name];
                  }}
                />
                <Legend
                  verticalAlign="bottom"
                  wrapperStyle={{ paddingTop: 20 }}
                />
                {folioRate && (
                  <Line
                    type="natural"
                    dataKey="folioRate"
                    stroke={colors?.folioRate || theme.palette.primary.main}
                    name="Foliorente"
                    dot={false}
                    strokeWidth={3}
                  />
                )}
                {indskudRate && (
                  <Line
                    type="natural"
                    dataKey="indskudRate"
                    stroke={colors?.indskudRate || theme.palette.secondary.main}
                    name="Indskudsbevisrente"
                    dot={false}
                    strokeWidth={3}
                  />
                )}
                {diskontoRate && (
                  <Line
                    type="natural"
                    dataKey="diskontoRate"
                    stroke={colors?.diskontoRate || theme.palette.warning.main}
                    name="Diskontorente"
                    dot={false}
                    strokeWidth={3}
                  />
                )}
                {udlanRate && (
                  <Line
                    type="natural"
                    dataKey="udlanRate"
                    stroke={colors?.udlanRate || theme.palette.error.main}
                    name="Udlånsrente"
                    dot={false}
                    strokeWidth={3}
                  />
                )}
              </LineChart>
            </ResponsiveContainer>
          </Box>
          {isLoading && (
            <Box
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1,
              }}
            >
              <FBLoading />
            </Box>
          )}
        </Card>
      </Box>
    </Box>
  );
} 