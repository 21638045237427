import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import { Calculator } from "../calculators/Calculator";
import {
  CalculatorBlock,
  RichTextBlock,
  RichTextNode,
  RichTextHeading as RichTextHeadingType,
  RichTextList as RichTextListType,
  ImageBlock,
  SEOBlock,
} from "../../services/StrapiTypes";
import { getImageProps } from "../../services/StrapiHelpers";

export const RichTextParagraph = ({ node }: { node: RichTextNode }) => {
  return (
    <Typography sx={{ color: "text.secondary", py: 0.5 }}>
      {node.children.map((child, idx) => {
        if (child.type === "link") {
          return (
            <a key={idx} href={child.url}>
              <Typography component="span" sx={{ color: 'text.primary' }}>
                <u>{child.children.map((c) => c.text).join("")}</u>
              </Typography>
            </a>
          );
        }
        if (child.type === "text") {
          let content: string | JSX.Element = child.text || "";
          const key = `text-${idx}`;

          if (child.bold) content = <strong key={`${key}-b`}>{content}</strong>;
          if (child.italic) content = <em key={`${key}-i`}>{content}</em>;
          if (child.underline) content = <u key={`${key}-u`}>{content}</u>;
          if (child.strikethrough) content = <s key={`${key}-s`}>{content}</s>;
          if (child.code)
            content = (
              <code key={`${key}-c`}>
                {content}
              </code>
            );
          return content;
        }
        return null;
      })}
    </Typography>
  );
};

const RichTextHeading = ({ node }: { node: RichTextHeadingType }) => (
  <Typography
    variant={`h${(node.level || 0) + 2}` as any}
    sx={{
      marginTop: (() => {
        switch (node.level) {
          case 2: return 4;
          case 3: return 3;
          default: return 2;
        }
      })(),
      marginBottom: 0,
    }}
  >
    {node.children.map((c) => ("text" in c ? c.text : "")).join("")}
  </Typography>
);

const RichTextList = ({ node }: { node: RichTextListType }) => (
  <List
    component={node.format === "ordered" ? "ol" : "ul"}
    sx={{
      listStyleType: node.format === "ordered" ? "decimal" : "disc",
      pl: 4,
      "& .MuiListItem-root": {
        display: "list-item",
        pl: 1,
      },
      color: 'text.secondary',
    }}
  >
    {node.children.map((item, idx) => (
      <ListItem key={idx} disablePadding>
        <ListItemText
          primary={item.children
            .map((c) => ("text" in c ? c.text : ""))
            .join("")}
        />
      </ListItem>
    ))}
  </List>
);

export const BlockComponentMap = {
  "blocks.rich-text": (block: RichTextBlock, index: number) => (
    <Box key={`rich-text-${index}`}>
      {block.text.map((node: RichTextNode, nodeIndex: number) => {
        switch (node.type) {
          case "paragraph":
            return <RichTextParagraph key={`para-${nodeIndex}`} node={node} />;
          case "heading":
            return <RichTextHeading key={`heading-${nodeIndex}`} node={node} />;
          case "list":
            return <RichTextList key={`list-${nodeIndex}`} node={node} />;
          default:
            return null;
        }
      })}
    </Box>
  ),
  "blocks.calculator": (block: CalculatorBlock, index: number) => (
    <Box key={`calculator-${index}`}>
      <Calculator calculatorType={block.calculatorType} />
    </Box>
  ),
  "blocks.image": (block: ImageBlock, index: number) => {
    const imageProps = getImageProps(block.image, "large", true);
    return (
      <Box key={index} sx={{ my: 2, display: 'flex', justifyContent: 'center' }}>
        <img
          {...imageProps}
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      </Box>
    );
  },
};
