import { Box, Card, Typography, Switch, Tooltip } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";

interface FBSwitchFieldInterface {
  value: boolean;
  setValue: (value: boolean) => void;
  textValue: string;
  title: string;
  valuePostFix?: string;
  disabled?: boolean;
  icon?: SvgIconComponent | string;
  iconColor?: string;
  sx?: any;
}

export function FBSwitchField(props: FBSwitchFieldInterface) {
  const {
    value,
    setValue,
    textValue,
    title,
    disabled = false,
    icon: Icon,
    iconColor,
    sx,
  } = props;

  return (
    <Card
      sx={{
        backgroundColor: "white",
        marginTop: 1,
        boxShadow: 3,
        height: { xs: "100%", sm: "56px" },
        ...sx,
      }}
    >
      <Box sx={{ paddingLeft: 2, paddingRight: 2, height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            height: "100%",
          }}
        >
          {Icon && (
            <>
              {typeof Icon === "string" ? (
                <img
                  src={Icon}
                  alt=""
                  style={{
                    width: 45,
                    height: 45,
                    filter: value && iconColor ? "none" : "grayscale(100%)",
                    opacity: value && iconColor ? 1 : 0.6,
                  }}
                />
              ) : (
                <Icon
                  sx={{
                    fontSize: 40,
                    color: value && iconColor ? iconColor : "text.secondary",
                    opacity: value && iconColor ? 1 : 0.9,
                  }}
                />
              )}
            </>
          )}
          <Tooltip title={textValue}>
            <Typography
              variant="body2"
              sx={{
                color: value ? "text.primary" : "text.secondary",
                fontWeight: value ? "500" : "normal",
                flex: 1,
              }}
            >
              {title}
            </Typography>
          </Tooltip>
          <Switch
            checked={value}
            onChange={(e) => setValue(e.target.checked)}
            disabled={disabled}
            sx={{
              '& .MuiSwitch-switchBase.Mui-checked': {
                '& + .MuiSwitch-track': {
                  backgroundColor: 'primary.dark',
                  opacity: 0.9,
                },
              },
            }}
          />
        </Box>
      </Box>
    </Card>
  );
} 