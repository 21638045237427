import { Card, useTheme } from "@mui/material";
import { axisClasses } from "@mui/x-charts";
import { LineChart } from "@mui/x-charts/LineChart";
import { useMemo } from "react";
import { formatCurrency } from "../../utils/formatters";
import { InflationData } from "../../services/BackendTypes";

interface InflationGraphProps {
  inflationData: InflationData[];
  startYear: number;
  endYear: number;
  initialValue: number;
}

export function InflationGraph(props: InflationGraphProps) {
  const { inflationData, startYear, endYear, initialValue } = props;

  const graphData = useMemo(() => {
    const years: number[] = [];
    const values: number[] = [];

    const isDescending = startYear > endYear;
    const actualStartYear = isDescending ? endYear : startYear;
    const actualEndYear = isDescending ? startYear : endYear;

    // Filter and sort data based on direction
    const relevantData = inflationData
      .filter(d => d.year >= actualStartYear - 1 && d.year <= actualEndYear)
      .sort((a, b) => isDescending ? b.year - a.year : a.year - b.year);

    // Start with initial value for the first year
    if (relevantData.length > 0) {
      years.push(actualStartYear);
      values.push(initialValue);
    }

    let currentValue = initialValue;

    // Calculate cumulative values starting from the second entry
    for (let i = 1; i < relevantData.length; i++) {
      const current = relevantData[i];
      const previous = relevantData[i - 1];

      if (current.year > actualStartYear && current.year <= actualEndYear) {
        const percentageChange = previous.index_value > 0
          ? ((current.index_value - previous.index_value) / previous.index_value)
          : 0;
        currentValue = currentValue * (1 + percentageChange);
        years.push(current.year);
        values.push(currentValue);
      }
    }

    return { years, values };
  }, [inflationData, startYear, endYear, initialValue]);


  const theme = useTheme();

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.getContrastText(
          theme.palette.primary.main
        ),
        marginTop: 1,
        borderRadius: 1,
        boxShadow: 3,
      }}
    >
      <LineChart
        series={[
          {
            label: "Værdi over tid",
            data: graphData.values,
            color: theme.palette.primary.main,
            valueFormatter: (value) => {
              return value === null ? "" : formatCurrency(value, 0);
            },
          },
        ]}
        height={298}
        slotProps={{ legend: { hidden: true } }}
        xAxis={[
          {
            data: graphData.years,
            scaleType: "band",
            valueFormatter: (value) => `${value}`,
            label: "Årstal",
          },
        ]}
        yAxis={[
          {
            label: "Værdi (DKK)",
            valueFormatter: (value) => formatCurrency(value, 0),
          },
        ]}
        sx={{
          margin: 0.5,
          [`& .${axisClasses.left} .${axisClasses.label}`]: {
            transform: "translateX(-30px)",
          },
        }}
        margin={{ top: 20, bottom: 50, left: 100, right: 10 }}
      />
    </Card>
  );
}
