// BoerneOpsparingGraph.tsx
import { Box, Card, useTheme } from "@mui/material";
import { BarChart } from "@mui/x-charts";

interface PayoutData {
  payoutAge: number;
  currentValue: number;
  totalDeposits: number;
  year: number;
  years: number;
}

interface BoerneOpsparingGraphProps {
  data: PayoutData[];
}

export const BoerneOpsparingGraph = ({ data }: BoerneOpsparingGraphProps) => {
  const theme = useTheme();

  // Transform data to include formatted labels
  const chartData = data.map((item) => ({
    ...item,
    age: `${item.payoutAge} år`,
    // Ensure values are numbers
    currentValue: Number(item.currentValue),
    totalDeposits: Number(item.totalDeposits),
  }));

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.getContrastText(
          theme.palette.primary.main
        ),
        marginTop: 1,
        borderRadius: 1,
        boxShadow: 3,
      }}
    >
      <BarChart
        series={[
          {
            label: "Værdi",
            data: chartData.map(item => item.currentValue),
            color: theme.palette.primary.main,
          },
          {
            label: "Indskudt",
            data: chartData.map(item => item.totalDeposits),
            color: theme.palette.accent.main,
          },
        ]}
        borderRadius={10}
        sx={{ margin: 0.5 }}
        height={352}
        xAxis={[
          {
            data: chartData.map(item => item.payoutAge),
            scaleType: "band",
            valueFormatter: (value) => `${value} år`,
          },
        ]}
        margin={{ top: 50, bottom: 50, left: 100, right: 10 }}
        slotProps={{
          legend: {
            direction: "row",
            position: { vertical: "top", horizontal: "middle" },
          },
        }}
      />
    </Card>
  );
};