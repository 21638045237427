import { useState } from "react";
import { FBSlider } from "../FBSlider";
import { useFetchDiskontoRate, useFetchFolioRate, useFetchIndskudRate, useFetchLatestUpdatedAt, useFetchUdlanRate } from "../../services/BackendService";
import { Box, Typography, useTheme } from "@mui/material";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import { useSearchParams } from "react-router-dom";
import { FBSwitchField } from "../FBSwitchField";
import { NationalBankRatesGraph } from "./NationalBankRatesGraph";

export function NationalBankRatesDisplay() {
  const [searchParams] = useSearchParams();
  const { data: udlanRate, isLoading: udlanRateLoading } = useFetchUdlanRate();
  const { data: indskudRate, isLoading: indskudRateLoading } = useFetchIndskudRate();
  const { data: folioRate, isLoading: folioRateLoading } = useFetchFolioRate();
  const { data: diskontoRate, isLoading: diskontoRateLoading } = useFetchDiskontoRate();

  const [showUdlanRate, setShowUdlanRate] = useState(true);
  const [showIndskudRate, setShowIndskudRate] = useState(false);
  const [showFolioRate, setShowFolioRate] = useState(false);
  const [showDiskontoRate, setShowDiskontoRate] = useState(false);

  const { data: latestUpdatedAt } = useFetchLatestUpdatedAt("national_bank_rates");

  const INITIAL_VALUES = {
    startYear: 1979,
    endYear: new Date().getFullYear(),
  };

  const isLoading = udlanRateLoading || indskudRateLoading || folioRateLoading || diskontoRateLoading;

  // Update state definitions to use search params
  const [startYear, setStartYear] = useState<number>(Number(searchParams.get('startYear')) || INITIAL_VALUES.startYear);
  const [endYear, setEndYear] = useState<number>(Number(searchParams.get('endYear')) || INITIAL_VALUES.endYear);

  const theme = useTheme();
  const colors = {
    udlanRate: theme.palette.primary.main,
    indskudRate: "lightgreen",
    folioRate: theme.palette.error.main,
    diskontoRate: theme.palette.warning.main
  };

  return (
    <Box sx={{ display: "flex", my: 2 }}>
      <Box sx={{ width: "100%", height: "100%" }}>
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
          <Box sx={{ flex: { xs: "1", md: "0 0 400px" }, display: "flex", flexDirection: "column" }}>
            <FBSlider
              title={"Start år"}
              min={INITIAL_VALUES.startYear}
              max={INITIAL_VALUES.endYear}
              editable={false}
              value={startYear}
              setValue={setStartYear}
              steps={1}
              formatter={(value) => value.toString()}
              icon={TodayOutlinedIcon}
            />
            <FBSlider
              title={"Slut år"}
              valuePostFix=""
              min={INITIAL_VALUES.startYear}
              max={INITIAL_VALUES.endYear}
              value={endYear}
              setValue={setEndYear}
              editable={false}
              steps={1}
              formatter={(value) => value.toString()}
              icon={EventOutlinedIcon}
            />
            <FBSwitchField
              title="Udlånsrente"
              value={showUdlanRate}
              setValue={setShowUdlanRate}
              textValue="Renten på udlån til pengeinstitutter fra Nationalbanken"
              icon={TrendingUpOutlinedIcon}
              iconColor={colors.udlanRate}
            />
            <FBSwitchField
              title="Indskudsbevisrente"
              value={showIndskudRate}
              setValue={setShowIndskudRate}
              textValue="Renten på indskudsbeviser"
              icon={TrendingUpOutlinedIcon}
              iconColor={colors.indskudRate}
            />
            <FBSwitchField
              title="Foliorente"
              value={showFolioRate}
              setValue={setShowFolioRate}
              textValue="Nationalbankens primære pengepolitiske rente"
              icon={TrendingUpOutlinedIcon}
              iconColor={colors.folioRate}
            />
            <FBSwitchField
              title="Diskontorente"
              value={showDiskontoRate}
              setValue={setShowDiskontoRate}
              textValue="Den officielle referencerente"
              icon={TrendingUpOutlinedIcon}
              iconColor={colors.diskontoRate}
            />
          </Box>

          <Box sx={{ flex: 1 }}>
            <NationalBankRatesGraph
              startYear={startYear}
              endYear={endYear}
              isLoading={isLoading}
              folioRate={showFolioRate ? folioRate?.map(d => ({ rate_date: d.rate_date, rate_value: d.rate_value })) : undefined}
              indskudRate={showIndskudRate ? indskudRate?.map(d => ({ rate_date: d.rate_date, rate_value: d.rate_value })) : undefined}
              udlanRate={showUdlanRate ? udlanRate?.map(d => ({ rate_date: d.rate_date, rate_value: d.rate_value })) : undefined}
              diskontoRate={showDiskontoRate ? diskontoRate?.map(d => ({ rate_date: d.rate_date, rate_value: d.rate_value })) : undefined}
              colors={colors}
            />
          </Box>
        </Box>
        {latestUpdatedAt && (
          <Box>
            <Typography variant="subtitle2" fontWeight={200} fontSize={12} paddingTop={1.5}>
              (Tal for rentesatser er opdateret {
                new Intl.DateTimeFormat('da-DK', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric'
                }
                ).format(new Date(latestUpdatedAt.last_updated_at))
              } fra <u><a href="https://www.nationalbanken.dk/da/vores-arbejde/stabile-priser-pengepolitik-og-dansk-oekonomi/officielle-rentesatser" target="_blank" rel="noopener noreferrer">Danmarks Nationalbank</a></u>)
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
